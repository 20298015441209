export const font = {
  family: {
    primary: {
      regular: "Inter-Regular",
      medium: "Inter-Medium",
      light: "Inter-Light",
    },
  },
  size: {
    xxsmall: "10px",
    small: "12px",
    smallPlus: "13px",
    normal: "14px",
    normalPlus: "15px",
    medium: "16px",
    mediumPlus: "18px",
    mediumLarge: "20px",
    large: "24px",
    mLarge: "25px",
    xlarge: "26px",
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 600,
  },
};
