import { ReactElement } from "react";
import { UserRole } from "types/user";
import RoleGuard from "./RoleGuard";

type GuardProps = {
  children: ReactElement | null;
  includeSoloPractitioner?: boolean;
  noRedirect?: boolean;
};

const OrgAdminsGuard = ({
  children,
  includeSoloPractitioner,
  noRedirect,
}: GuardProps) => {
  return (
    <RoleGuard
      roles={[
        UserRole.ORGANIZATION_ADMIN,
        UserRole.ADMIN,
        ...(includeSoloPractitioner ? [UserRole.SOLO_PRACTITIONER] : []),
      ]}
      noRedirect={noRedirect}
    >
      {children}
    </RoleGuard>
  );
};

export default OrgAdminsGuard;
