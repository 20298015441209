import { ReactNode, useEffect, useRef, useState } from "react";

import * as Styled from "./styles";

export enum OptionType {
  BUTTON = "button",
  SEPARATOR = "separator",
}

export type IOption =
  | {
      title: string;
      type: OptionType.BUTTON;
      onClick: () => void;
    }
  | {
      title?: string;
      type: OptionType.SEPARATOR;
    };

type DropdownProps = {
  options: IOption[];
  children: ReactNode;
};

export const Dropdown = ({ children, options = [] }: DropdownProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  useEffect(() => {
    const handleWindowClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowPopUp(false);
      }
    };

    window.addEventListener("mousedown", handleWindowClick);
    return () => {
      window.removeEventListener("mousedown", handleWindowClick);
    };
  }, []);

  const handleOptionClick = (fn: () => void) => {
    fn();
    return setShowPopUp(false);
  };

  const renderOptions = () =>
    options.map((option) =>
      option.type === OptionType.SEPARATOR ? (
        <Styled.ListSeparator key={option.title}>
          {option.title}
        </Styled.ListSeparator>
      ) : (
        <Styled.ListItem
          key={option.title}
          onClick={() => handleOptionClick(option.onClick)}
        >
          {option.title}
        </Styled.ListItem>
      )
    );

  return (
    <Styled.Wrapper ref={modalRef}>
      <Styled.ClickedCotainer onClick={() => setShowPopUp((prev) => !prev)}>
        {children}
      </Styled.ClickedCotainer>
      {showPopUp && (
        <Styled.SelectedList>{renderOptions()}</Styled.SelectedList>
      )}
    </Styled.Wrapper>
  );
};
