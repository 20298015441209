import {
  IClientResponse,
  ICreateClientPayload,
  IDeleteClientPayload,
  IGetClientPayload,
  IUpdateClientPayload,
} from "types/api/client";
import { IClientResponseData } from "types/api/clients";
import { apiInstance, formatRequestError } from "utils";
import { formatClient } from "utils/client";

export const getClientService = async ({
  id,
}: IGetClientPayload): Promise<IClientResponse> => {
  try {
    const { data }: { data: IClientResponseData } = await apiInstance.get(
      `client/${id}`
    );

    return {
      client: formatClient(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createClientService = async ({
  firstName,
  lastName,
  birthDate,
  email,
}: ICreateClientPayload): Promise<IClientResponse> => {
  try {
    const { data }: { data: IClientResponseData } = await apiInstance.post(
      "client",
      {
        firstName,
        lastName,
        dateOfBirth: birthDate,
        ...(!!email && { email }),
      }
    );

    return {
      client: formatClient(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateClientService = async ({
  id,
  firstName,
  lastName,
  birthDate,
  email,
}: IUpdateClientPayload): Promise<IClientResponse> => {
  try {
    const { data }: { data: IClientResponseData } = await apiInstance.patch(
      `client/${id}`,
      {
        ...(!!firstName && { firstName }),
        ...(!!lastName && { lastName }),
        ...(!!birthDate && { dateOfBirth: birthDate }),
        ...((!!email || email === "") && { email: email || null }),
      }
    );

    return {
      client: formatClient(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const deleteClientService = async ({
  id,
}: IDeleteClientPayload): Promise<void> => {
  try {
    await apiInstance.delete(`client/${id}`);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
