import styled from "styled-components";
import { colors, font } from "theme";

export const Container = styled.div`
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 10px;
  background-color: ${colors.solitude};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const Text = styled.div`
  font-family: ${font.family.primary.regular};
  font-size: ${font.size.medium};
`;

export const DropImage = styled.div`
  font-family: ${font.family.primary.regular};
  font-size: ${font.size.medium};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.solitude};
  padding: 10px;
  border-radius: 10px;
`;

export const Error = styled.p`
  color: ${colors.red};
  font-size: ${font.size.small};
  max-width: 220px;
`;
