import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { IUserState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import { getProfile, setNewUserPassword, updateProfile } from "./actions";

const initialState: IUserState = {
  error: null,
  error2: null,
  loading: false,
  mutationLoading: false,
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.error2 = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.mutationLoading = false;
      state.error = null;
      state.user = payload.user;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.mutationLoading = true;
      state.error = null;
    });
    builder.addCase(updateProfile.rejected, (state, { payload }) => {
      state.mutationLoading = false;
      state.error = payload || null;
    });
    builder.addCase(setNewUserPassword.fulfilled, (state) => {
      state.mutationLoading = false;
      state.error2 = null;
    });
    builder.addCase(setNewUserPassword.pending, (state) => {
      state.mutationLoading = true;
      state.error2 = null;
    });
    builder.addCase(setNewUserPassword.rejected, (state, { payload }) => {
      state.mutationLoading = false;
      state.error2 = payload || null;
    });
    builder.addMatcher(isAnyOf(getProfile.pending), (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getProfile.rejected), (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addMatcher(isAnyOf(getProfile.fulfilled), (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.user = payload.user;
    });
  },
});

export const { clearError } = userSlice.actions;

export default userSlice.reducer;
