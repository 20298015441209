import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4148 4.73209L16.268 6.58447L14.4148 4.73209ZM15.6065 3.10022L10.5954 8.11134C10.3365 8.3699 10.1599 8.69933 10.0879 9.05809L9.625 11.3751L11.942 10.9113C12.3007 10.8396 12.6297 10.6637 12.8887 10.4047L17.8999 5.39359C18.0505 5.24301 18.1699 5.06424 18.2514 4.86749C18.3329 4.67074 18.3748 4.45986 18.3748 4.24691C18.3748 4.03395 18.3329 3.82307 18.2514 3.62632C18.1699 3.42957 18.0505 3.2508 17.8999 3.10022C17.7493 2.94963 17.5705 2.83018 17.3738 2.74869C17.177 2.66719 16.9661 2.62524 16.7532 2.62524C16.5402 2.62524 16.3294 2.66719 16.1326 2.74869C15.9359 2.83018 15.7571 2.94963 15.6065 3.10022V3.10022Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.625 13.125V15.75C16.625 16.2141 16.4406 16.6592 16.1124 16.9874C15.7842 17.3156 15.3391 17.5 14.875 17.5H5.25C4.78587 17.5 4.34075 17.3156 4.01256 16.9874C3.68437 16.6592 3.5 16.2141 3.5 15.75V6.125C3.5 5.66087 3.68437 5.21575 4.01256 4.88756C4.34075 4.55937 4.78587 4.375 5.25 4.375H7.875"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default React.memo(Icon);
