import { apiInstance, formatRequestError } from "utils";
import {
  ICreateTemplatePayload,
  IDeleteTemplatePayload,
  IGetTemplatePayload,
  IGetTemplateResponse,
  IGetTemplateResponseData,
  ITemplateListResponseData,
  ITemplatesListPayload,
  ITemplatesListResponse,
  IUpdateTemplatePayload,
} from "types/api/templates";
import { formatShortTemplate, formatTemplate } from "utils/templates";

export const getTemplatesListService = async (
  args: ITemplatesListPayload | undefined
): Promise<ITemplatesListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: ITemplateListResponseData } = await apiInstance.get(
      `organization/my/templates`,
      {
        params: rest,
      }
    );

    return {
      templates: data.templates.map((item) => formatShortTemplate(item)),
      count: data.metadata.itemsAmount,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const getTemplateService = async ({
  id,
}: IGetTemplatePayload): Promise<IGetTemplateResponse> => {
  try {
    const { data }: { data: IGetTemplateResponseData } = await apiInstance.get(
      `template/${id}`
    );

    return {
      template: formatTemplate(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createTemplateService = async (
  payload: ICreateTemplatePayload
): Promise<void> => {
  try {
    const {
      name,
      description,
      morningRoutine,
      eveningRoutine,
      notesForClient,
      internalNotes,
      procedures,
    } = payload;
    await apiInstance.post("template", {
      name,
      description,
      morningRoutineProducts: morningRoutine,
      eveningRoutineProducts: eveningRoutine,
      procedures,
      clientNotes: notesForClient,
      internalNotes,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateTemplateService = async (
  payload: IUpdateTemplatePayload
): Promise<void> => {
  try {
    const {
      templateId,
      name,
      description,
      morningRoutine,
      eveningRoutine,
      notesForClient,
      internalNotes,
      procedures,
    } = payload;

    await apiInstance.patch(`template/${templateId}`, {
      ...(!!name && { name }),
      ...(!!description && { description }),
      ...(!!morningRoutine && { morningRoutineProducts: morningRoutine }),
      ...(!!eveningRoutine && { eveningRoutineProducts: eveningRoutine }),
      ...(!!procedures && { procedures }),
      ...((!!notesForClient || internalNotes === "") && {
        clientNotes: notesForClient,
      }),
      ...((!!internalNotes || notesForClient === "") && { internalNotes }),
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const deleteTemplateService = async ({
  id,
}: IDeleteTemplatePayload): Promise<void> => {
  try {
    await apiInstance.delete(`template/${id}`);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
