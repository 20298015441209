import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { t } from "i18next";

import { toaster } from "constants/index";
import {
  loginService,
  resetPasswordService,
  setNewPasswordService,
  signUpService,
} from "services/auth";
import {
  IAuthResponse,
  ILoginPayload,
  IResetPasswordPayload,
  ISetNewPasswordPayload,
  ISignUpPayload,
} from "types/api/auth";
import { IThunkAPI } from "types/common";

export const login = createAsyncThunk<IAuthResponse, ILoginPayload, IThunkAPI>(
  "login",
  async (payload, thunkAPI) => {
    try {
      const data = await loginService(payload);

      return data;
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const signup = createAsyncThunk<
  IAuthResponse,
  ISignUpPayload,
  IThunkAPI
>("signup", async (payload, thunkAPI) => {
  try {
    const data = await signUpService(payload);

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resetPassword = createAsyncThunk<
  void,
  IResetPasswordPayload,
  IThunkAPI
>("resetPassword", async (payload, thunkAPI) => {
  try {
    await resetPasswordService(payload);

    toast.success(t("messages.forgotPasswordLinkSent", { style: toaster }));
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const setNewPassword = createAsyncThunk<
  void,
  ISetNewPasswordPayload,
  IThunkAPI
>("setNewPassword", async (payload, thunkAPI) => {
  try {
    await setNewPasswordService(payload);

    if (payload.onSuccess) payload.onSuccess();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const logout = createAction("auth/logout");
