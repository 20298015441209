import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.58327 6.03334L9.23743 0.379172C9.41313 0.203478 9.63674 0.115631 9.90827 0.115631C10.1798 0.115631 10.4034 0.203478 10.5791 0.379172C10.7548 0.554867 10.8426 0.778478 10.8426 1.05001C10.8426 1.32153 10.7548 1.54515 10.5791 1.72084L4.2541 8.04584C4.06243 8.23751 3.83882 8.33334 3.58327 8.33334C3.32771 8.33334 3.1041 8.23751 2.91243 8.04584L0.420768 5.55417C0.245073 5.37848 0.157227 5.15487 0.157227 4.88334C0.157227 4.61181 0.245073 4.3882 0.420768 4.21251C0.596462 4.03681 0.820074 3.94896 1.0916 3.94896C1.36313 3.94896 1.58674 4.03681 1.76244 4.21251L3.58327 6.03334Z"
        fill={props.color}
      />
    </svg>
  );
};
export default React.memo(Icon);
