import { IClientResponseData } from "types/api/clients";
import { IClient } from "types/client";

export const formatClient = (data: IClientResponseData): IClient => {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    birthDate: data.dateOfBirth,
    email: data.email || "",
  };
};
