import { createAsyncThunk } from "@reduxjs/toolkit";

import { IThunkAPI } from "types/common";
import {
  ICreateTemplatePayload,
  IDeleteTemplatePayload,
  IGetTemplatePayload,
  IGetTemplateResponse,
  ITemplatesListPayload,
  ITemplatesListResponse,
  IUpdateTemplatePayload,
} from "types/api/templates";
import {
  createTemplateService,
  deleteTemplateService,
  getTemplateService,
  getTemplatesListService,
  updateTemplateService,
} from "services/templates";
import { setRegimenBuilderValues } from "store/reducers/regimen-builder";

export const getTemplatesList = createAsyncThunk<
  ITemplatesListResponse,
  ITemplatesListPayload | undefined,
  IThunkAPI
>("getTemplatesList", async (args, thunkAPI) => {
  try {
    const data = await getTemplatesListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTemplate = createAsyncThunk<
  IGetTemplateResponse,
  IGetTemplatePayload,
  IThunkAPI
>("getTemplate", async (args, thunkAPI) => {
  try {
    const data = await getTemplateService(args);

    if (data) {
      thunkAPI.dispatch(setRegimenBuilderValues(data.template));
    }

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createTemplate = createAsyncThunk<
  void,
  ICreateTemplatePayload,
  IThunkAPI
>("createTemplate", async (args, thunkAPI) => {
  try {
    await createTemplateService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateTemplate = createAsyncThunk<
  void,
  IUpdateTemplatePayload,
  IThunkAPI
>("updateTemplate", async (args, thunkAPI) => {
  try {
    await updateTemplateService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteTemplate = createAsyncThunk<
  void,
  IDeleteTemplatePayload,
  IThunkAPI
>("deleteTemplate", async (args, thunkAPI) => {
  try {
    await deleteTemplateService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
