import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";

import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import usersReducer from "./reducers/users";
import clientsReducer from "./reducers/clients";
import clientReducer from "./reducers/client";
import brandsReducer from "./reducers/brands";
import productsReducer from "./reducers/products";
import proceduresReducer from "./reducers/procedures";
import subscriptionReducer from "./reducers/subscription";
import dashboardReducer from "./reducers/dashboard";
import regimenBuilderReducer from "./reducers/regimen-builder";
import templatesReducer from "./reducers/templates";
import organizationReducer from "./reducers/organization";
import customersReducer from "./reducers/customers";
import organizationsUsersReducer from "./reducers/organizations-users";

const persistConfig = {
  key: "store",
  whitelist: ["auth"],
  storage,
};

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  users: usersReducer,
  clients: clientsReducer,
  client: clientReducer,
  brands: brandsReducer,
  products: productsReducer,
  procedures: proceduresReducer,
  subscription: subscriptionReducer,
  dashboard: dashboardReducer,
  regimenBuilder: regimenBuilderReducer,
  templates: templatesReducer,
  organization: organizationReducer,
  customers: customersReducer,
  organizationsUsers: organizationsUsersReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
