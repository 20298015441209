import ColorPicker from "components/color-picker";
import ImageDropZone from "components/image-drop-zone";
import { Input } from "components/input";
import Loader from "components/Loader";
import ModalContainer from "components/modals/modal-container";
import { SVGIcon } from "components/svg-icon";
import {
  EMAIL_REGEX,
  ORGANIZATION_LOGO_MIN_HEIGHT,
  ORGANIZATION_LOGO_MIN_WIDTH,
  PHONE_REGEX,
} from "constants/index";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import ReactCrop, { centerCrop, Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import {
  getOrganizationDetails,
  getOrganizationExamplePDF,
  updateOrganizationDetails,
} from "store/reducers/organization/actions";
import { organization as organizationSelector } from "store/selectors";
import { IOrganizationDetailsUpdatePayload } from "types/api/organization";
import * as Styled from "./styles";

type Form = {
  email: string;
  phone: string;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const OrganizationDetailsModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const { details, loading, mutationLoading, pdfLoading } =
    useSelector(organizationSelector);

  const [pdfColorHexMorningRoutine, setPdfColorHexMorningRoutine] = useState<
    string | null
  >(null);
  const [pdfColorHexEveningRoutine, setPdfColorHexEveningRoutine] = useState<
    string | null
  >(null);
  const [pdfColorHexNotes, setPdfColorHexNotes] = useState<string | null>(null);
  const [pdfColorHexFooter, setPdfColorHexFooter] = useState<string | null>(
    null
  );
  const [image, setImage] = useState<File | string | null>(null);
  const [imageError, setImageError] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [isCropperShown, setIsCropperShown] = useState<boolean>(false);
  const [cropImageScale, setCropImageScale] = useState<{
    x: number;
    y: number;
  }>();
  const cropImageUrl = useMemo(() => {
    if (typeof image === "string") return image;
    if (image instanceof File) return URL.createObjectURL(image);
  }, [image]);

  useEffect(() => {
    dispatch(getOrganizationDetails());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      email: "",
      phone: "",
    },
  });

  useEffect(() => {
    if (details) {
      setPdfColorHexMorningRoutine(details.pdfColorHexMorningRoutine);
      setPdfColorHexEveningRoutine(details.pdfColorHexEveningRoutine);
      setPdfColorHexNotes(details.pdfColorHexNotes);
      setPdfColorHexFooter(details.pdfColorHexFooter);
      setImage(details.logo);
      setImageError(null);
      reset({
        email: details.contactEmail || "",
        phone: details.contactPhone || "",
      });
    }
  }, [details, reset]);

  const values = watch();

  const isDirty = useMemo(() => {
    return (
      values.email !== (details?.contactEmail || "") ||
      values.phone !== (details?.contactPhone || "") ||
      pdfColorHexMorningRoutine !== details?.pdfColorHexMorningRoutine ||
      pdfColorHexEveningRoutine !== details?.pdfColorHexEveningRoutine ||
      pdfColorHexNotes !== details?.pdfColorHexNotes ||
      pdfColorHexFooter !== details?.pdfColorHexFooter ||
      image !== details?.logo
    );
  }, [
    values.email,
    values.phone,
    details?.contactEmail,
    details?.contactPhone,
    details?.pdfColorHexMorningRoutine,
    details?.pdfColorHexEveningRoutine,
    details?.pdfColorHexNotes,
    details?.pdfColorHexFooter,
    details?.logo,
    pdfColorHexMorningRoutine,
    pdfColorHexEveningRoutine,
    pdfColorHexNotes,
    pdfColorHexFooter,
    image,
  ]);

  const onImageDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (
          img.width >= ORGANIZATION_LOGO_MIN_WIDTH &&
          img.height >= ORGANIZATION_LOGO_MIN_HEIGHT
        ) {
          setImageError(null);
          setImage(file);
          setIsCropperShown(true);
        } else {
          setImageError(
            t("modals.organizationDetails.image.sizeError", {
              width: ORGANIZATION_LOGO_MIN_WIDTH,
              height: ORGANIZATION_LOGO_MIN_HEIGHT,
            })
          );
        }
        URL.revokeObjectURL(img.src);
      };
    },
    [setImage, setImageError]
  );

  const handleCropImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const cropImage = e.currentTarget;
      const { width, height, naturalWidth, naturalHeight } = cropImage;
      const cropImageScaleX = naturalWidth / width;
      const cropImageScaleY = naturalHeight / height;
      setCropImageScale({ x: cropImageScaleX, y: cropImageScaleY });
      setCrop(
        centerCrop(
          {
            unit: "px",
            width: width * 0.9,
            height: height * 0.9,
          },
          width,
          height
        )
      );
    },
    [setCropImageScale]
  );

  const handleApplyCrop = useCallback(async () => {
    if (!cropImageScale) return;
    if (!(image instanceof File)) return;
    if (!crop || crop.width === 0 || crop.height === 0) return;

    const canvas = document.createElement("canvas");
    const { x: scaleX, y: scaleY } = cropImageScale;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    if (!ctx) throw new Error("No 2d context");

    const imageBitmap = await createImageBitmap(image);

    ctx.drawImage(
      imageBitmap,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob(
      (blob) => {
        if (!blob) return;
        const croppedImage = new File([blob], image.name, {
          type: "image/png",
        });

        setImage(croppedImage);
        setIsCropperShown(false);
      },
      "image/png",
      1
    );
  }, [crop, cropImageScale, image]);

  const onSubmit = useCallback(
    (data: Form) => {
      const payload: IOrganizationDetailsUpdatePayload = {
        ...data,
        ...(!!pdfColorHexMorningRoutine &&
          pdfColorHexMorningRoutine !== details?.pdfColorHexMorningRoutine && {
            pdfColorHexMorningRoutine,
          }),
        ...(!!pdfColorHexEveningRoutine &&
          pdfColorHexEveningRoutine !== details?.pdfColorHexEveningRoutine && {
            pdfColorHexEveningRoutine,
          }),
        ...(!!pdfColorHexNotes &&
          pdfColorHexNotes !== details?.pdfColorHexNotes && {
            pdfColorHexNotes,
          }),
        ...(!!pdfColorHexFooter &&
          pdfColorHexFooter !== details?.pdfColorHexFooter && {
            pdfColorHexFooter,
          }),
        ...(!!image &&
          image !== details?.logo &&
          typeof image !== "string" && { logo: image }),
        onSuccess: () => dispatch(getOrganizationDetails()),
      };

      dispatch(updateOrganizationDetails(payload));
    },
    [
      pdfColorHexMorningRoutine,
      pdfColorHexEveningRoutine,
      pdfColorHexNotes,
      pdfColorHexFooter,
      image,
      details,
      dispatch,
    ]
  );

  const onDownloadExamplePDF = useCallback(() => {
    dispatch(getOrganizationExamplePDF());
  }, [dispatch]);

  return (
    <ModalContainer isOpen={isOpen}>
      {isCropperShown && (
        <Styled.CropperContainer>
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            minWidth={1}
            minHeight={1}
          >
            {cropImageUrl && (
              <img
                src={cropImageUrl}
                onLoad={handleCropImageLoad}
                alt={image instanceof File ? image.name : cropImageUrl}
              />
            )}
          </ReactCrop>
          <Styled.CropperButtonsContainer>
            <Styled.ActionButton onClick={() => handleApplyCrop()}>
              {t("common.apply")}
            </Styled.ActionButton>
          </Styled.CropperButtonsContainer>
        </Styled.CropperContainer>
      )}

      {!isCropperShown && (
        <Styled.Container>
          <Styled.Title>{t("modals.organizationDetails.title")}</Styled.Title>
          <Styled.CloseIconContainer onClick={onClose}>
            <SVGIcon name="close" width={10.5} height={10.5} />
          </Styled.CloseIconContainer>

          <Styled.SettingsContainer>
            {loading ? (
              <Styled.LoaderContainer>
                <Loader size={50} />
              </Styled.LoaderContainer>
            ) : (
              <>
                <Styled.SettingsRowContainer>
                  <Styled.LeftContainer>
                    <ImageDropZone
                      onDrop={onImageDrop}
                      value={image}
                      onRemove={() => setImage(null)}
                      error={imageError}
                    />
                    <Styled.ImageDescription>
                      {`*${t("modals.organizationDetails.image.uploadLogo")}`}
                      <ul>
                        <li>
                          {t("modals.organizationDetails.image.logoFormat")}
                        </li>
                        <li>
                          {t("modals.organizationDetails.image.logoSize")}
                        </li>
                      </ul>
                    </Styled.ImageDescription>
                  </Styled.LeftContainer>

                  <Styled.RightContainer>
                    <Input
                      name={"email"}
                      autoCapitalize="off"
                      label={`${t("modals.organizationDetails.contactEmail")}`}
                      control={control}
                      rules={{
                        pattern: {
                          value: EMAIL_REGEX,
                          message: t("auth.emailValidError"),
                        },
                      }}
                      errorMessage={errors.email?.message}
                    />
                    <Input
                      name={"phone"}
                      type="tel"
                      autoCapitalize="off"
                      label={`${t("modals.organizationDetails.contactPhone")}`}
                      control={control}
                      rules={{
                        pattern: {
                          value: PHONE_REGEX,
                          message: t("common.invalidPhoneNumber"),
                        },
                      }}
                      errorMessage={errors.phone?.message}
                    />
                  </Styled.RightContainer>
                </Styled.SettingsRowContainer>
                <Styled.SettingsRowContainer>
                  <Styled.ColorPickerContainer>
                    <Styled.ColorTitle>
                      {t(
                        "modals.organizationDetails.pdfColorHexMorningRoutine"
                      )}
                    </Styled.ColorTitle>
                    <ColorPicker
                      initialColorHEX={details?.pdfColorHexMorningRoutine}
                      onChange={({ hex }) => {
                        setPdfColorHexMorningRoutine(hex);
                      }}
                    />
                  </Styled.ColorPickerContainer>
                  <Styled.ColorPickerContainer>
                    <Styled.ColorTitle>
                      {t(
                        "modals.organizationDetails.pdfColorHexEveningRoutine"
                      )}
                    </Styled.ColorTitle>
                    <ColorPicker
                      initialColorHEX={details?.pdfColorHexEveningRoutine}
                      onChange={({ hex }) => {
                        setPdfColorHexEveningRoutine(hex);
                      }}
                    />
                  </Styled.ColorPickerContainer>
                  <Styled.ColorPickerContainer>
                    <Styled.ColorTitle>
                      {t("modals.organizationDetails.pdfColorHexNotes")}
                    </Styled.ColorTitle>
                    <ColorPicker
                      initialColorHEX={details?.pdfColorHexNotes}
                      onChange={({ hex }) => {
                        setPdfColorHexNotes(hex);
                      }}
                    />
                  </Styled.ColorPickerContainer>
                  <Styled.ColorPickerContainer>
                    <Styled.ColorTitle>
                      {t("modals.organizationDetails.pdfColorHexFooter")}
                    </Styled.ColorTitle>
                    <ColorPicker
                      initialColorHEX={details?.pdfColorHexFooter}
                      onChange={({ hex }) => {
                        setPdfColorHexFooter(hex);
                      }}
                    />
                  </Styled.ColorPickerContainer>
                </Styled.SettingsRowContainer>
                <Styled.SettingsRowContainer>
                  <Styled.ExapmleButton
                    variant="secondary"
                    loading={pdfLoading}
                    onClick={onDownloadExamplePDF}
                    disabled={isDirty}
                  >
                    {isDirty
                      ? t("modals.organizationDetails.saveToDownloadExample")
                      : t("modals.organizationDetails.downloadExample")}
                  </Styled.ExapmleButton>
                </Styled.SettingsRowContainer>
              </>
            )}
          </Styled.SettingsContainer>

          <Styled.ButtonsContainer>
            <Styled.ActionButton variant="secondary" onClick={onClose}>
              {t("common.cancel")}
            </Styled.ActionButton>
            <Styled.ActionButton
              disabled={!isDirty || mutationLoading}
              loading={mutationLoading}
              onClick={handleSubmit(onSubmit)}
            >
              {t("common.save")}
            </Styled.ActionButton>
          </Styled.ButtonsContainer>
        </Styled.Container>
      )}
    </ModalContainer>
  );
};

export default OrganizationDetailsModal;
