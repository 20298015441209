export const EMAIL_REGEX = /^[+\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}$/;
export const PASSWORD_MIN_LENGTH = 8;

export const USER_NAME_MAX_LENGTH = 100;
export const USER_EMAIL_MAX_LENGTH = 100;

export const CLIENT_NAME_MAX_LENGTH = 100;

export const BRAND_NAME_MIN_LENGTH = 2;
export const BRAND_NAME_MAX_LENGTH = 100;

export const PRODUCT_NAME_MIN_LENGTH = 2;
export const PRODUCT_NAME_MAX_LENGTH = 100;
export const PRODUCT_NOTE_MAX_LENGTH = 1000;

export const PROCEDURE_NAME_MIN_LENGTH = 2;
export const PROCEDURE_NAME_MAX_LENGTH = 100;
export const PROCEDURE_NOTE_MAX_LENGTH = 1000;

export const TEMPLATE_NAME_MAX_LENGTH = 50;
export const TEMPLATE_DESCRIPTION_MAX_LENGTH = 100;

export const ORGANIZATION_LOGO_MIN_WIDTH = 300;
export const ORGANIZATION_LOGO_MIN_HEIGHT = 300;
