import { apiInstance, formatRequestError } from "utils";
import {
  ICreateRegimenPDFPreviewPayload,
  ICreateRegimenPDFPreviewResponse,
  ICreateRegimenPayload,
  IDeleteRegimenPayload,
  IGetRegimenPDFPayload,
  IGetRegimenPDFResponse,
  IGetRegimenPayload,
  IGetRegimenResponse,
  IGetRegimenResponseData,
  IRegimenListResponseData,
  IRegimensListPayload,
  IRegimensListResponse,
  IUpdateRegimenPayload,
} from "types/api/regimen";
import { formatRegimen, formatShortRegimen } from "utils/regimens";

export const getRegimensListService = async (
  args: IRegimensListPayload
): Promise<IRegimensListResponse> => {
  try {
    const { onError, onSuccess, clientId, ...rest } = args;
    const { data }: { data: IRegimenListResponseData } = await apiInstance.get(
      `client/${clientId}/regimens`,
      {
        params: rest,
      }
    );

    return {
      regimens: data.regimens.map((item) => formatShortRegimen(item)),
      count: data.metadata.itemsAmount,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const getRegimenService = async ({
  id,
}: IGetRegimenPayload): Promise<IGetRegimenResponse> => {
  try {
    const { data }: { data: IGetRegimenResponseData } = await apiInstance.get(
      `regimen/${id}`
    );

    return {
      regimen: formatRegimen(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const getRegimenPDFService = async ({
  id,
}: IGetRegimenPDFPayload): Promise<IGetRegimenPDFResponse> => {
  try {
    const response = await apiInstance.get(`regimen/${id}/pdf`, {
      responseType: "arraybuffer",
    });

    const blob = new Blob([response.data], { type: "application/pdf" });

    return {
      pdfUrl: URL.createObjectURL(blob),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createRegimenPDFPreviewService = async (
  payload: ICreateRegimenPDFPreviewPayload
): Promise<ICreateRegimenPDFPreviewResponse> => {
  try {
    const {
      clientId,
      morningRoutine,
      eveningRoutine,
      notesForClient,
      internalNotes,
      procedures,
    } = payload;

    const response = await apiInstance.post(
      `client/${clientId}/regimen/pdf-preview`,
      {
        morningRoutineProducts: morningRoutine,
        eveningRoutineProducts: eveningRoutine,
        procedures,
        clientNotes: notesForClient,
        internalNotes,
      },
      {
        responseType: "arraybuffer",
      }
    );

    const blob = new Blob([response.data], { type: "application/pdf" });

    return { pdfData: URL.createObjectURL(blob) };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createRegimenService = async (
  payload: ICreateRegimenPayload
): Promise<void> => {
  try {
    const {
      clientId,
      morningRoutine,
      eveningRoutine,
      notesForClient,
      internalNotes,
      procedures,
    } = payload;

    await apiInstance.post(`client/${clientId}/regimen`, {
      morningRoutineProducts: morningRoutine,
      eveningRoutineProducts: eveningRoutine,
      procedures,
      clientNotes: notesForClient,
      internalNotes,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateRegimenService = async (
  payload: IUpdateRegimenPayload
): Promise<void> => {
  try {
    const {
      regimenId,
      morningRoutine,
      eveningRoutine,
      notesForClient,
      internalNotes,
      procedures,
    } = payload;

    await apiInstance.patch(`regimen/${regimenId}`, {
      ...(!!morningRoutine && { morningRoutineProducts: morningRoutine }),
      ...(!!eveningRoutine && { eveningRoutineProducts: eveningRoutine }),
      ...(!!procedures && { procedures }),
      ...((!!notesForClient || internalNotes === "") && {
        clientNotes: notesForClient,
      }),
      ...((!!internalNotes || notesForClient === "") && { internalNotes }),
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const deleteRegimenService = async (
  payload: IDeleteRegimenPayload
): Promise<void> => {
  try {
    await apiInstance.delete(`regimen/${payload.id}`);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
