import { createAsyncThunk } from "@reduxjs/toolkit";
import { IActionProps, IThunkAPI } from "types/common";
import {
  IGetOrganizationPayload,
  IOrganizationDetailsResponse,
  IOrganizationDetailsUpdatePayload,
} from "types/api/organization";
import {
  getOrganizationDetailsByIdService,
  getOrganizationDetailsService,
  getOrganizationExamplePDFService,
  updateOrganizationDetailsService,
} from "services/organization";

export const getOrganizationDetails = createAsyncThunk<
  IOrganizationDetailsResponse,
  IActionProps | undefined,
  IThunkAPI
>("getOrganizationDetails", async (args, thunkAPI) => {
  try {
    const data = await getOrganizationDetailsService();

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getOrganizationDetailsById = createAsyncThunk<
  IOrganizationDetailsResponse,
  IGetOrganizationPayload,
  IThunkAPI
>("getOrganizationDetailsById", async (args, thunkAPI) => {
  try {
    const data = await getOrganizationDetailsByIdService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateOrganizationDetails = createAsyncThunk<
  IOrganizationDetailsResponse,
  IOrganizationDetailsUpdatePayload,
  IThunkAPI
>("updateOrganizationDetails", async (args, thunkAPI) => {
  try {
    const data = await updateOrganizationDetailsService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getOrganizationExamplePDF = createAsyncThunk<
  void,
  IActionProps | undefined,
  IThunkAPI
>("getOrganizationExamplePDF", async (args, thunkAPI) => {
  try {
    const data = await getOrganizationExamplePDFService();

    if (data) {
      window.open(data.url, "_blank");
    }

    if (args?.onSuccess) {
      args.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

