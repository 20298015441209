import styled from "styled-components";
import { colors, font } from "theme";

export const Wrapper = styled.div`
  position: relative;
`;

export const ClickedCotainer = styled.div`
  cursor: pointer;
`;

export const SelectedList = styled.div`
  box-sizing: border-box;

  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 99999;
  background: ${colors.white};

  border: 1px solid ${colors.solitude};

  box-shadow: 0px 4px 53px ${colors.shadow};
  border-radius: 5px;
`;

export const ListItem = styled.div`
  width: 160px;
  padding: 10px 15px;
  size: 14px;
  font-family: "Inter-Light";
  cursor: pointer;
  &:hover {
    background-color: ${colors.lightPink};
  }
`;

export const ListSeparator = styled.div`
  width: 160px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  size: 14px;
  font-family: ${font.family.primary.medium};
`;
