import styled from "styled-components";
import { colors, font } from "theme";

export const Container = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
`;

export const ColorPickerContainer = styled.div<{ $visible?: boolean }>`
  position: absolute;
  bottom: 50px;
  left: 0;
  border-radius: 10px;
  background-color: ${colors.white};
  padding: 30px 10px 10px 10px;
  box-shadow: 0 4px 53px ${colors.shadowMedium};
  display: ${({ $visible }) => ($visible ? "block" : "none")};

  & .react-colorful {
    height: 200px;
    width: 238px;
    border-radius: 10px;
  }

  & .react-colorful__saturation {
    margin: 0 0 11px 0;
    border-radius: 5px;
    border-bottom: none;
  }

  & .react-colorful__hue,
  .react-colorful__alpha {
    height: 10px;
    border-radius: 5px;
  }

  & .react-colorful__hue-pointer,
  .react-colorful__alpha-pointer {
    width: 20px;
    height: 20px;
  }
`;

export const ColorBox = styled.div<{ $color: string }>`
  width: 61px;
  height: 35px;
  border-radius: 5px;
  background-color: ${({ $color }) => $color};
  cursor: pointer;
  border: 1px solid ${colors.solitude};
`;

export const InputsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Label = styled.label`
  font-size: ${font.size.medium};
`;

export const Input = styled.input<{ $width?: string }>`
  padding: 4px;
  border-radius: 5px;
  border: 1px solid ${colors.solitude};
  text-align: center;
  width: ${({ $width }) => $width || "auto"};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const CMYKContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const EditButtonContainer = styled.div`
  svg {
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid ${colors.solitude};
    background-color: ${colors.transparent};
    stroke: ${colors.white};

    &:hover {
      background-color: ${colors.lightRed};
      border: ${`1px solid ${colors.lightRed}`};

      path {
        stroke: ${colors.white};
      }
    }
  }
`;
