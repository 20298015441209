import { apiInstance, formatRequestError } from "utils";
import {
  ICreateProductPayload,
  IProductsListPayload,
  IProductsListResponse,
  IProductsListResponseData,
  IUpdateProductPayload,
} from "types/api/products";
import { formatProduct } from "utils/products";

export const getProductsListService = async (
  args?: IProductsListPayload
): Promise<IProductsListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: IProductsListResponseData } = await apiInstance.get(
      "organization/my/products",
      {
        params: rest,
      }
    );

    return {
      products: data.products.map((item) => formatProduct(item)),
      count: data.metadata.itemsAmount,
      page: data.metadata.page,
      hasMore: data.metadata.hasNext,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createProductService = async ({
  name,
  note,
  brandId,
  isActive = true,
}: ICreateProductPayload): Promise<void> => {
  try {
    await apiInstance.post("product", {
      name,
      note,
      brandId,
      isActive,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateProductService = async ({
  id,
  name,
  note,
  brandId,
  isActive,
}: IUpdateProductPayload): Promise<void> => {
  try {
    await apiInstance.patch(`product/${id}`, {
      ...(!!name && { name }),
      ...(!!note && { note }),
      ...(!!brandId && { brandId }),
      ...((isActive === true || isActive === false) && { isActive }),
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
