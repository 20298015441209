import { IProcedureResponseData } from "types/api/procedures";
import { IProcedure } from "types/procedures";

export const formatProcedure = (data: IProcedureResponseData): IProcedure => {
  return {
    id: data.id,
    name: data.name,
    note: data.note,
    isActive: data.isActive,
  };
};
