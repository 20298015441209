import { FC, InputHTMLAttributes } from "react";
import { Controller, Control } from "react-hook-form";

import { IconTypes } from "components/svg-icon/icons";
import { SVGIcon } from "components/svg-icon";
import { colors } from "theme";
import {
  Container,
  InputContainer,
  InputField,
  IconContainer,
  ErrMessage,
  Label,
} from "./styles";

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  limit?: number;
  control: Control<any>;
  rules?: any;
  name: string;
  errorMessage?: string;
  rigthIcon?: IconTypes;
  rigthIconColor?: string;
  onRigthIconClick?: () => void;
}

export const Input: FC<IInputProps> = ({
  label,
  control,
  rules,
  name,
  errorMessage,
  rigthIcon,
  rigthIconColor = colors.primary,
  onRigthIconClick = () => {},
  ...rest
}): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Container {...rest} tabIndex={-1}>
          {!!label && (
            <Label
              htmlFor={name}
              color={!!errorMessage ? colors.red : colors.primary}
            >
              {label}
            </Label>
          )}
          <InputContainer $haserror={!!errorMessage}>
            <InputField id={name} {...field} {...rest} />
            {!!rigthIcon && (
              <IconContainer onClick={onRigthIconClick} data-testid="rightIcon">
                <SVGIcon
                  name={rigthIcon}
                  width={20}
                  height={20}
                  color={rigthIconColor}
                />
              </IconContainer>
            )}
          </InputContainer>
          {!!errorMessage && <ErrMessage>{errorMessage}</ErrMessage>}
        </Container>
      )}
    />
  );
};
