import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 0C3.35476 0 0 3.35438 0 7.5C0 11.6452 3.35438 15 7.5 15C11.6452 15 15 11.6456 15 7.5C15 3.35481 11.6456 0 7.5 0ZM7.5 13.9535C3.94154 13.9535 1.04651 11.0585 1.04651 7.5C1.04651 3.94151 3.94154 1.04651 7.5 1.04651C11.0585 1.04651 13.9535 3.94151 13.9535 7.5C13.9535 11.0585 11.0585 13.9535 7.5 13.9535Z"
        fill={props.color}
      />
      <path
        d="M7.49993 6.25188C7.05567 6.25188 6.73977 6.43949 6.73977 6.71591V10.4772C6.73977 10.7142 7.05567 10.9511 7.49993 10.9511C7.92444 10.9511 8.26994 10.7142 8.26994 10.4772V6.71585C8.26994 6.43946 7.92444 6.25188 7.49993 6.25188ZM7.49993 3.93188C7.0458 3.93188 6.69043 4.25767 6.69043 4.63281C6.69043 5.00799 7.04583 5.34364 7.49993 5.34364C7.94419 5.34364 8.29962 5.00799 8.29962 4.63281C8.29962 4.25767 7.94416 3.93188 7.49993 3.93188Z"
        fill={props.color}
      />
    </svg>
  );
};
export default React.memo(Icon);
