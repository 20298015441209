import { apiInstance, formatRequestError } from "utils";
import {
  ICreateUserPayload,
  IDeleteUserPayload,
  IResetUserPasswordPayload,
  IUpdateUserPayload,
  IUserListPayload,
  IUserListResponse,
  IUserListResponseData,
} from "types/api/users";
import { formatOrgUser } from "utils/user";

export const getUserListService = async (
  args?: IUserListPayload
): Promise<IUserListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: IUserListResponseData } = await apiInstance.get(
      "organization/my/participants",
      {
        params: rest,
      }
    );

    return {
      users: data.users.map((item) => formatOrgUser(item)),
      count: data.metadata.itemsAmount,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createUserService = async ({
  name,
  email,
  role,
  isActive = true,
}: ICreateUserPayload): Promise<void> => {
  try {
    await apiInstance.post("profile", {
      fullName: name,
      email,
      role,
      isActive,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateUserService = async ({
  id,
  name,
  email,
  isActive,
  role,
}: IUpdateUserPayload): Promise<void> => {
  try {
    await apiInstance.patch(`profile/${id}`, {
      ...(!!name && { fullName: name }),
      ...(!!email && { email }),
      ...(!!role && { role }),
      ...((isActive === true || isActive === false) && { isActive }),
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const resetUserPasswordService = async ({
  id,
}: IResetUserPasswordPayload): Promise<void> => {
  try {
    await apiInstance.post(`profile/${id}/reset-password`);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const deleteUserService = async ({
  id,
}: IDeleteUserPayload): Promise<void> => {
  try {
    await apiInstance.delete(`profile/${id}`);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
