import { v4 as uuidv4 } from "uuid";
import {
  ICreateTemplatePayload,
  IGetTemplateResponseData,
  ITemplateShortResponseData,
} from "types/api/templates";
import { IProcedure } from "types/procedures";
import { IRegimenBuilderState } from "types/store";
import { ITemplate, ITemplateShort } from "types/templates";

export const formatShortTemplate = (
  data: ITemplateShortResponseData
): ITemplateShort => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
  };
};

export const formatTemplate = (item: IGetTemplateResponseData): ITemplate => {
  return {
    id: item.id,
    name: item.name,
    description: item.description,
    notesForClient: item.clientNotes,
    internalNotes: item.internalNotes,
    morningRoutine: item.templateToMorningRoutineProduct.map((item) => ({
      product: {
        id: item.product.id,
        dndId: uuidv4(),
        name: item.product.name,
        note: item.product.note,
        isActive: item.product.isActive,
        brand: {
          id: item.product.brand.id,
          name: item.product.brand.name,
          isActive: item.product.brand.isActive,
        },
      },
      notesForClient: item.note,
    })),
    eveningRoutine: item.templateToEveningRoutineProduct.map((item) => ({
      product: {
        id: item.product.id,
        dndId: uuidv4(),
        name: item.product.name,
        note: item.product.note,
        isActive: item.product.isActive,
        brand: {
          id: item.product.brand.id,
          name: item.product.brand.name,
          isActive: item.product.brand.isActive,
        },
      },
      notesForClient: item.note,
    })),
    procedures: item.templateToProcedure.map((item) => ({
      id: uuidv4(),
      procedure: {
        id: item.procedure.id,
        name: item.procedure.name,
        note: item.procedure.note,
        isActive: item.procedure.isActive,
      },
      notesForClient: item.note,
    })),
  };
};

export const getCreateTemplatePayload = (
  payload: Pick<
    IRegimenBuilderState,
    | "morningRoutine"
    | "eveningRoutine"
    | "procedures"
    | "internalNotes"
    | "notesForClient"
  > & { name: string; description: string }
): ICreateTemplatePayload => {
  return {
    name: payload.name,
    description: payload.description,
    morningRoutine: payload.morningRoutine.map((item) => ({
      id: item.product.id,
    })),
    eveningRoutine: payload.eveningRoutine.map((item) => ({
      id: item.product.id,
    })),
    procedures: payload.procedures
      .filter((item) => !!item.procedure)
      .map((item) => ({
        id: (item.procedure as IProcedure).id,
      })),
    internalNotes: payload.internalNotes,
    notesForClient: payload.notesForClient,
  };
};
