import { Outlet } from "react-router-dom";

import { SVGIcon } from "components/svg-icon";
import GuestGuard from "layout/guards/GuestGuard";

import * as Styled from "./styles";

const AuthLayout = () => {
  return (
    <GuestGuard>
      <Styled.Wrapper>
        <SVGIcon name="logo" width="115" height="43" />

        <Styled.Container>
          <Outlet />
        </Styled.Container>
      </Styled.Wrapper>
    </GuestGuard>
  );
};

export default AuthLayout;
