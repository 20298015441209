import React from "react";

import Loader from "components/Loader";
import { colors } from "theme";

import * as Styled from "./styles";

export type ButtonVariant = "primary" | "secondary";

export interface IButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonVariant;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  loading?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
  children,
  disabled = false,
  variant = "primary",
  onClick = () => {},
  type,
  loading = false,
  ...rest
}) => {
  return (
    <Styled.ButtonStyled
      {...rest}
      disabled={disabled || loading}
      $variant={variant}
      onClick={onClick}
      type={type}
    >
      {loading && (
        <Styled.LoaderContainer>
          <Loader
            size={16}
            color={variant === "secondary" ? colors.secondary : colors.white}
          />
        </Styled.LoaderContainer>
      )}
      {children}
    </Styled.ButtonStyled>
  );
};
