import styled from "styled-components";

import { breakpoints, layout } from "theme";

export const Container = styled.main`
  padding: 97px ${layout.padding.normal} 30px ${layout.padding.normal};
  flex: 1;
  overflow-x: hidden;
  @media (max-width: ${breakpoints.xl}) {
    padding: 97px ${layout.padding.tablet} 30px ${layout.padding.tablet};
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
