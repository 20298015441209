import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";

import { INIT_PAGE } from "constants/index";
import { IBrandToggleIsActivePayload, IBrandsState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import {
  createBrand,
  deleteBrand,
  getBrandActiveList,
  getBrandList,
  updateBrand,
} from "./actions";

const initialState: IBrandsState = {
  error: null,
  loading: false,
  mutationLoading: false,
  brands: [],
  count: 0,
  activeBrands: [],
  activeLoading: false,
  activeHasMore: false,
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
    toggleIsActive(state, action: PayloadAction<IBrandToggleIsActivePayload>) {
      const { id } = action.payload;
      state.brands = state.brands.map((brand) =>
        brand.id === id ? { ...brand, isActive: !brand.isActive } : brand
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(getBrandList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.brands = payload.brands;
      state.count = payload.count;
    });
    builder.addCase(getBrandActiveList.fulfilled, (state, { payload }) => {
      state.activeLoading = false;
      state.error = null;
      state.activeBrands =
        payload.page === INIT_PAGE
          ? payload.brands
          : [...state.activeBrands, ...payload.brands];
      state.activeHasMore = payload.hasMore;
    });
    builder.addCase(getBrandActiveList.rejected, (state, { payload }) => {
      state.activeLoading = false;
      state.error = payload || null;
    });
    builder.addCase(getBrandActiveList.pending, (state) => {
      state.activeLoading = true;
      state.error = null;
    });
    builder.addCase(getBrandList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBrandList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addMatcher(
      isAnyOf(
        createBrand.fulfilled,
        updateBrand.fulfilled,
        deleteBrand.fulfilled
      ),
      (state) => {
        state.mutationLoading = false;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createBrand.pending, updateBrand.pending, deleteBrand.pending),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createBrand.rejected, updateBrand.rejected, deleteBrand.rejected),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
  },
});

export const { clearError, toggleIsActive } = brandsSlice.actions;

export default brandsSlice.reducer;
