import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.62118 2.59446C2.34251 2.78983 2.1191 3.05648 1.97337 3.36767C1.84973 3.59916 1.64282 3.77267 1.39622 3.85166C1.14962 3.93065 0.882586 3.90895 0.651361 3.79114C0.420137 3.67333 0.242776 3.4686 0.156637 3.22007C0.0704991 2.97155 0.0823081 2.69863 0.189577 2.4588C0.455617 1.91348 0.871968 1.33832 1.51578 0.901517C2.16492 0.457935 2.98565 0.200195 3.99128 0.200195C4.86655 0.200195 5.81232 0.464717 6.56388 1.03988C6.98288 1.35224 7.32267 1.76213 7.55497 2.23544C7.78727 2.70876 7.90535 3.23181 7.8994 3.76107C7.8994 5.16235 7.21701 6.09428 6.53328 6.76576C6.28055 7.014 6.00253 7.25139 5.7631 7.45487L5.56756 7.62173C5.36239 7.79286 5.16905 7.97824 4.98893 8.17654V10.035C4.98893 10.3048 4.88382 10.5636 4.69672 10.7544C4.50963 10.9452 4.25587 11.0524 3.99128 11.0524C3.72669 11.0524 3.47293 10.9452 3.28584 10.7544C3.09874 10.5636 2.99363 10.3048 2.99363 10.035V8.03682C2.99363 7.71533 3.08275 7.35314 3.32219 7.03842C3.61483 6.6586 3.96867 6.3371 4.27727 6.06986L4.5207 5.86232L4.52203 5.86096C4.75082 5.66698 4.95301 5.4947 5.14722 5.30207C5.63673 4.82051 5.9041 4.38507 5.9041 3.76107C5.90998 3.54775 5.86398 3.33626 5.77021 3.14548C5.67643 2.9547 5.53781 2.79056 5.3667 2.66771C5.01021 2.39505 4.50207 2.23498 3.99128 2.23498C3.33416 2.23498 2.9085 2.39912 2.62118 2.59446ZM5.32147 14.4437C5.32147 14.8034 5.18133 15.1485 4.93187 15.4029C4.68241 15.6573 4.34407 15.8002 3.99128 15.8002C3.63849 15.8002 3.30015 15.6573 3.05069 15.4029C2.80123 15.1485 2.66108 14.8034 2.66108 14.4437C2.66108 14.0839 2.80123 13.7389 3.05069 13.4845C3.30015 13.2301 3.63849 13.0872 3.99128 13.0872C4.34407 13.0872 4.68241 13.2301 4.93187 13.4845C5.18133 13.7389 5.32147 14.0839 5.32147 14.4437Z"
        fill="#2B4262"
      />
    </svg>
  );
};
export default React.memo(Icon);
