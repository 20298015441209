import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";

import { INIT_PAGE } from "constants/index";
import { IProcedureToggleIsActivePayload, IProceduresState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import {
  createProcedure,
  getProceduresActiveList,
  getProceduresList,
  updateProcedure,
} from "./actions";

const initialState: IProceduresState = {
  error: null,
  loading: false,
  mutationLoading: false,
  procedures: [],
  count: 0,
  activeProcedures: [],
  activeLoading: false,
  activeHasMore: false,
};

const proceduresSlice = createSlice({
  name: "procedures",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
    toggleIsActive(
      state,
      action: PayloadAction<IProcedureToggleIsActivePayload>
    ) {
      const { id } = action.payload;
      state.procedures = state.procedures.map((procedure) =>
        procedure.id === id
          ? { ...procedure, isActive: !procedure.isActive }
          : procedure
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // getProceduresList
    builder.addCase(getProceduresList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.procedures = payload.procedures;
      state.count = payload.count;
    });
    builder.addCase(getProceduresList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProceduresList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    // getProceduresActiveList
    builder.addCase(getProceduresActiveList.fulfilled, (state, { payload }) => {
      state.activeLoading = false;
      state.error = null;
      state.activeProcedures =
        payload.page === INIT_PAGE
          ? payload.procedures
          : [...state.activeProcedures, ...payload.procedures];
      state.activeHasMore = payload.hasMore;
    });
    builder.addCase(getProceduresActiveList.rejected, (state, { payload }) => {
      state.activeLoading = false;
      state.error = payload || null;
    });
    builder.addCase(getProceduresActiveList.pending, (state) => {
      state.activeLoading = true;
      state.error = null;
    });
    // Matchers
    builder.addMatcher(
      isAnyOf(createProcedure.fulfilled, updateProcedure.fulfilled),
      (state) => {
        state.mutationLoading = false;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createProcedure.pending, updateProcedure.pending),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createProcedure.rejected, updateProcedure.rejected),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
  },
});

export const { clearError, toggleIsActive } = proceduresSlice.actions;

export default proceduresSlice.reducer;
