import styled from "styled-components";

import { colors } from "theme";

export const Container = styled.div`
  display: flex;
  outline: none !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  border-radius: 10px;
`;

export const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3500;
  background: ${colors.shadowBlack};
`;
