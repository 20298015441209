import { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { PATH_AUTH, PATH_MAIN } from "constants/index";
import { auth, user as userSelector } from "store/selectors";
import { UserRole } from "types/user";

export type GuardProps = {
  children: ReactElement | null;
};

const AuthGuard = ({ children }: GuardProps) => {
  const { accessToken: token } = useSelector(auth);
  const { user, loading } = useSelector(userSelector);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      navigate(PATH_AUTH.LOGIN, {
        state: {
          from: "",
        },
        replace: true,
      });
      return;
    }

    if (
      !!user &&
      user.role === UserRole.GUEST &&
      location.pathname !== PATH_MAIN.SELECT_PLAN
    ) {
      navigate(PATH_MAIN.SELECT_PLAN, {
        state: {
          from: "",
        },
        replace: true,
      });
    }
  }, [token, user, navigate, location]);

  if (loading || !user) return null;

  return children;
};

export default AuthGuard;
