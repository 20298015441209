export enum UserRole {
  OWNER = "OWNER",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  SOLO_PRACTITIONER = "SOLO_PRACTITIONER",
  ADMIN = "PRACTITIONER_ADMIN",
  PRACTITIONER = "PRACTITIONER",
  GUEST = "GUEST",
}

export type OrgUserRole = UserRole.ADMIN | UserRole.PRACTITIONER;

export interface IUser {
  name: string;
  email: string;
  role: UserRole;
  isSubscribed: boolean;
  isActive: boolean;
}

export interface IOrgUser {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  isActive: boolean;
}
