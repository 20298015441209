import styled from "styled-components";

import { colors, font, spacing } from "theme";

export const Container = styled.div`
  width: 354px;
`;

export const InputContainer = styled.div<{
  $haserror: boolean;
}>`
  height: 48px;
  display: flex;
  align-items: center;
  background: ${colors.guyabano};
  outline: 1px solid ${colors.solitude};
  outline-color: ${(props) => (props.$haserror ? colors.red : colors.solitude)};
  border-radius: 5px;
`;

export const InputField = styled.input`
  flex: 1;
  align-items: center;
  height: 100%;
  padding: 0px 8px;
  margin: 0;
  border: none;
  background: ${colors.guyabano};
  &:focus {
    outline: none;
  }
`;
export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px ${spacing[2]}px;
  cursor: pointer;
`;

export const ErrMessage = styled.p`
  color: ${colors.red};
  font-size: ${font.size.small};
`;

export const Label = styled.label`
  display: block;
  color: ${(props) => props.color};
  margin-bottom: ${spacing[1]}px;
  text-transform: capitalize;
`;
