import { createSlice } from "@reduxjs/toolkit";

import { logout } from "store/reducers/auth/actions";
import { ISubscriptionState } from "types/store";
import {
  getSubscription,
  getSubscriptionPlans,
  getTransactionsList,
  getUpdatePaymentDetailsLink,
} from "./actions";

const initialState: ISubscriptionState = {
  error: null,
  loading: false,
  subscription: null,
  transactions: [],
  listLoading: false,
  lastTransactionId: null,
  hasMore: false,
  updateDetailsLink: null,
  updateDetailsLoading: false,
  subscriptionPlans: [],
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // getSubscription
    builder.addCase(getSubscription.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.subscription = payload.subscription;
    });
    builder.addCase(getSubscription.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubscription.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    // getTransactionsList
    builder.addCase(getTransactionsList.fulfilled, (state, { payload }) => {
      state.listLoading = false;
      state.error = null;
      state.transactions = !state.lastTransactionId
        ? payload.transactions
        : [...state.transactions, ...payload.transactions];
      state.hasMore = payload.hasMore;
      state.lastTransactionId =
        payload.transactions[payload.transactions.length - 1]?.id || null;
    });
    builder.addCase(getTransactionsList.pending, (state) => {
      state.listLoading = true;
      state.error = null;
    });
    builder.addCase(getTransactionsList.rejected, (state, { payload }) => {
      state.listLoading = false;
      state.error = payload || null;
    });
    // getUpdatePaymentDetailsLink
    builder.addCase(
      getUpdatePaymentDetailsLink.fulfilled,
      (state, { payload }) => {
        state.updateDetailsLoading = false;
        state.error = null;
        state.updateDetailsLink = payload;
      }
    );
    builder.addCase(getUpdatePaymentDetailsLink.pending, (state) => {
      state.updateDetailsLoading = true;
      state.error = null;
    });
    builder.addCase(
      getUpdatePaymentDetailsLink.rejected,
      (state, { payload }) => {
        state.updateDetailsLoading = false;
        state.error = payload || null;
      }
    );
    builder.addCase(getSubscriptionPlans.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubscriptionPlans.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subscriptionPlans = payload.subscriptionPlans;
    });
    builder.addCase(getSubscriptionPlans.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
  },
});

export const { clearError, clearState } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
