import { FC, InputHTMLAttributes } from "react";

import { colors } from "theme";

import * as Styled from "./styles";

export interface IHeadlineProps extends InputHTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  color?: string;
}

export const Headline: FC<IHeadlineProps> = ({
  children,
  color = colors.primary,
  ...rest
}): JSX.Element => {
  return (
    <Styled.Headline color={color} {...rest}>
      {children}
    </Styled.Headline>
  );
};
