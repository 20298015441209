import { ReactElement, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { PATH_MAIN } from "constants/index";
import { auth } from "store/selectors";

export type GuardProps = {
  children: ReactElement | null;
};

const GuestGuard = ({ children }: GuardProps) => {
  const { accessToken: token } = useSelector(auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (token) {
      const path = location?.state?.from || PATH_MAIN.ROOT;
      navigate(path, {
        state: {
          from: "",
        },
        replace: true,
      });
    }
  }, [token, navigate, location]);

  if (token) return null;

  return children;
};

export default GuestGuard;
