import {
  IGetOrganizationPayload,
  IOrganizationDetailsResponse,
  IOrganizationDetailsResponseData,
  IOrganizationDetailsUpdatePayload,
} from "types/api/organization";
import { apiInstance, formatRequestError } from "utils";
import { formatOrganizationDetails } from "utils/organization";

export const getOrganizationDetailsService =
  async (): Promise<IOrganizationDetailsResponse> => {
    try {
      const { data }: { data: IOrganizationDetailsResponseData } =
        await apiInstance.get(`organization/my`);

      return {
        details: formatOrganizationDetails(data),
      };
    } catch (e: any) {
      const errorMessage: string = formatRequestError(
        e?.response?.data || e.message
      );

      throw errorMessage;
    }
  };

export const getOrganizationDetailsByIdService = async ({
  id,
}: IGetOrganizationPayload): Promise<IOrganizationDetailsResponse> => {
  try {
    const { data }: { data: IOrganizationDetailsResponseData } =
      await apiInstance.get(`organization/${id}`);

    return {
      details: formatOrganizationDetails(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateOrganizationDetailsService = async ({
  email,
  phone,
  logo,
  pdfColorHexMorningRoutine,
  pdfColorHexEveningRoutine,
  pdfColorHexNotes,
  pdfColorHexFooter,
}: IOrganizationDetailsUpdatePayload): Promise<IOrganizationDetailsResponse> => {
  try {
    const form = new FormData();
    !!email && form.append("email", email);
    !!phone && form.append("phone", phone);
    !!logo && form.append("logoFile", logo);

    !!pdfColorHexMorningRoutine &&
      form.append(
        "pdfColorHexMorningRoutine",
        String(pdfColorHexMorningRoutine)
      );
    !!pdfColorHexEveningRoutine &&
      form.append(
        "pdfColorHexEveningRoutine",
        String(pdfColorHexEveningRoutine)
      );
    !!pdfColorHexNotes &&
      form.append("pdfColorHexNotes", String(pdfColorHexNotes));
    !!pdfColorHexFooter &&
      form.append("pdfColorHexFooter", String(pdfColorHexFooter));

    const { data }: { data: IOrganizationDetailsResponseData } =
      await apiInstance.patch(`organization/my`, form);

    return {
      details: formatOrganizationDetails(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const getOrganizationExamplePDFService = async (): Promise<{
  url: string;
}> => {
  try {
    const response = await apiInstance.get(`regimen/example/pdf`, {
      responseType: "arraybuffer",
    });

    const blob = new Blob([response.data], { type: "application/pdf" });

    return {
      url: URL.createObjectURL(blob),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
