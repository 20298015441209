import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCustomerListService,
  updateCustomerOrganizationDetailsService,
} from "services/customers";
import {
  ICustomerListPayload,
  ICustomerListResponse,
  IUpdateCustomerOrganizationDetailsPayload,
} from "types/api/customers";

import { IThunkAPI } from "types/common";

export const getCustomerList = createAsyncThunk<
  ICustomerListResponse,
  ICustomerListPayload | undefined,
  IThunkAPI
>("getCustomerList", async (args, thunkAPI) => {
  try {
    const data = await getCustomerListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateCustomerOrganizationDetails = createAsyncThunk<
  void,
  IUpdateCustomerOrganizationDetailsPayload,
  IThunkAPI
>("updateCustomerOrganizationDetails", async (args, thunkAPI) => {
  try {
    const data = await updateCustomerOrganizationDetailsService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
