import { t } from "i18next";

import { CONTACT_EMAIL } from "constants/index";
import ModalContainer from "components/modals/modal-container";

import * as Styled from "./styles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal = ({ isOpen, onClose }: Props) => {
  return (
    <ModalContainer isOpen={isOpen}>
      <Styled.Container>
        <Styled.Title>{t("modals.help.title")}</Styled.Title>
        <Styled.SubTitle
          dangerouslySetInnerHTML={{
            __html: t("modals.help.subTitle", {
              email: `<a href="mailto:${CONTACT_EMAIL}">${CONTACT_EMAIL}</a>`,
            }),
          }}
        />
        <Styled.Button variant="primary" onClick={onClose}>
          {t("common.ok")}
        </Styled.Button>
      </Styled.Container>
    </ModalContainer>
  );
};

export default HelpModal;
