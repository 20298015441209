import { v4 as uuidv4 } from "uuid";

import { EDITABLE_REGIMEN_HRS_AGE } from "constants/regimen";
import {
  ICreateRegimenPayload,
  IGetRegimenResponseData,
  IRegimenShortResponseData,
} from "types/api/regimen";
import { IRegimen, IRegimenShort } from "types/regimen";
import { IRegimenBuilderState } from "types/store";
import { IProcedure } from "types/procedures";

export const formatShortRegimen = (
  item: IRegimenShortResponseData
): IRegimenShort => {
  const createdDate = new Date(item.createdDate);
  const timeDifference = new Date().getTime() - createdDate.getTime();
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  const isEditable = hoursDifference <= EDITABLE_REGIMEN_HRS_AGE;

  return {
    id: item.id,
    createdDate: item.createdDate,
    createdBy: {
      name: item.practitioner.fullName,
    },
    isEditable,
  };
};

export const formatRegimen = (item: IGetRegimenResponseData): IRegimen => {
  return {
    id: item.id,
    clientId: item.client.id,
    notesForClient: item.clientNotes,
    internalNotes: item.internalNotes,
    morningRoutine: item.regimenToMorningRoutineProduct.map((item) => ({
      product: {
        id: item.product.id,
        dndId: uuidv4(),
        name: item.product.name,
        note: item.product.note,
        isActive: item.product.isActive,
        brand: {
          id: item.product.brand.id,
          name: item.product.brand.name,
          isActive: item.product.brand.isActive,
        },
      },
      notesForClient: item.note,
    })),
    eveningRoutine: item.regimenToEveningRoutineProduct.map((item) => ({
      product: {
        id: item.product.id,
        dndId: uuidv4(),
        name: item.product.name,
        note: item.product.note,
        isActive: item.product.isActive,
        brand: {
          id: item.product.brand.id,
          name: item.product.brand.name,
          isActive: item.product.brand.isActive,
        },
      },
      notesForClient: item.note,
    })),
    procedures: item.regimenToProcedure.map((item) => ({
      id: item.id,
      procedure: {
        id: item.procedure.id,
        name: item.procedure.name,
        note: item.procedure.note,
        isActive: item.procedure.isActive,
      },
      notesForClient: item.note,
    })),
  };
};

export const getCreateRegimenPayload = (
  clientId: string,
  payload: Pick<
    IRegimenBuilderState,
    | "morningRoutine"
    | "eveningRoutine"
    | "procedures"
    | "internalNotes"
    | "notesForClient"
  >
): ICreateRegimenPayload => {
  return {
    clientId,
    morningRoutine: payload.morningRoutine.map((item) => ({
      id: item.product.id,
      note: item.notesForClient,
    })),
    eveningRoutine: payload.eveningRoutine.map((item) => ({
      id: item.product.id,
      note: item.notesForClient,
    })),
    procedures: payload.procedures
      .filter((item) => !!item.procedure)
      .map((item) => ({
        id: (item.procedure as IProcedure).id,
        note: item.notesForClient,
      })),
    internalNotes: payload.internalNotes,
    notesForClient: payload.notesForClient,
  };
};
