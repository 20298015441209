import { FC } from "react";
import { ClipLoader } from "react-spinners";
import { LoaderSizeProps } from "react-spinners/helpers/props";

import { colors } from "theme";

interface Props extends LoaderSizeProps {
  size?: number;
  color?: string;
}

const Loader: FC<Props> = ({ size, color, ...rest }): JSX.Element => {
  return (
    <ClipLoader
      color={color || colors.secondary}
      loading={true}
      size={size || 40}
      aria-label="Loading Spinner"
      data-testid="loader"
      {...rest}
    />
  );
};

export default Loader;
