import { createSlice } from "@reduxjs/toolkit";

import { IDashboardState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import { getOrganizationMetrics } from "./actions";

const initialState: IDashboardState = {
  error: null,
  loading: false,
  clientMetrics: null,
  regimenMetrics: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(getOrganizationMetrics.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.clientMetrics = payload.clientMetrics;
      state.regimenMetrics = payload.regimenMetrics;
    });
    builder.addCase(getOrganizationMetrics.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrganizationMetrics.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
  },
});

export const { clearError } = dashboardSlice.actions;

export default dashboardSlice.reducer;
