import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { IClientsState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import { getClientList } from "./actions";

const initialState: IClientsState = {
  error: null,
  loading: false,
  mutationLoading: false,
  clients: [],
  count: 0,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(getClientList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.clients = payload.clients;
      state.count = payload.count;
    });
    builder.addMatcher(isAnyOf(getClientList.pending), (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(
      isAnyOf(getClientList.rejected),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      }
    );
  },
});

export const { clearError } = clientsSlice.actions;

export default clientsSlice.reducer;
