import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";
import { toast } from "react-toastify";

import { toaster } from "constants/index";
import { getProfileService, updateProfileService } from "services/user";
import {
  ISetNewUserPasswordPayload,
  IUpdateProfilePayload,
  IUserResponse,
} from "types/api/user";
import { IActionProps, IThunkAPI } from "types/common";
import { logout } from "store/reducers/auth/actions";

export const getProfile = createAsyncThunk<
  IUserResponse,
  IActionProps | undefined,
  IThunkAPI
>("getProfile", async (args, thunkAPI) => {
  try {
    const data = await getProfileService();

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateProfile = createAsyncThunk<
  IUserResponse,
  IUpdateProfilePayload,
  IThunkAPI
>("updateProfile", async (payload, thunkAPI) => {
  try {
    const data = await updateProfileService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    toast.success(t("messages.dataSaved"), { style: toaster });

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const setNewUserPassword = createAsyncThunk<
  void,
  ISetNewUserPasswordPayload,
  IThunkAPI
>("setNewUserPassword", async (payload, thunkAPI) => {
  try {
    await updateProfileService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    toast.success(t("messages.passwordChanged"), { style: toaster });
    thunkAPI.dispatch(logout());
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
