import { t } from "i18next";
import { useDropzone, DropzoneOptions } from "react-dropzone";

import * as Styled from "./styles";

interface Props {
  onDrop: DropzoneOptions["onDrop"];
  error?: string | null;
  value?: File | string | null;
  onRemove?: () => void;
}

const ImageDropZone = ({ onDrop, error, value }: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    multiple: false,
  });

  return (
    <>
      <Styled.Container {...getRootProps()}>
        <input {...getInputProps()} />
        {!!value && (
          <Styled.Image
            src={typeof value === "string" ? value : URL.createObjectURL(value)}
          />
        )}
        {!value && !isDragActive && (
          <Styled.Text>{t("imageDropZone.dragOrClick")}</Styled.Text>
        )}
        {isDragActive && (
          <Styled.DropImage>{t("imageDropZone.dropImage")}</Styled.DropImage>
        )}
      </Styled.Container>
      {!!error && <Styled.Error>{error}</Styled.Error>}
    </>
  );
};

export default ImageDropZone;
