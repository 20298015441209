import { ICustomerResponseData } from "types/api/customers";
import { ICustomer } from "types/customers";

export const formatCustomer = (data: ICustomerResponseData): ICustomer => {
  return {
    id: data.id,
    name: data.fullName,
    email: data.email,
    role: data.role,
    organization: {
      id: data.organization?.id || "",
      name: data.organization?.name || "",
      email: data.organization?.email || "",
      phone: data.organization?.phone || "",
      pdfColorHexMorningRoutine:
        data.organization?.pdfColorHexMorningRoutine || "",
      pdfColorHexEveningRoutine:
        data.organization?.pdfColorHexEveningRoutine || "",
      pdfColorHexNotes: data.organization?.pdfColorHexNotes || "",
      pdfColorHexFooter: data.organization?.pdfColorHexFooter || "",
    },
    isActive: data.isActive,
    isSubscribed: data.isSubscriptionActive,
  };
};
