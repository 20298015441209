import { IActionProps } from "types/common";
import {
  ITransaction,
  IUserSubscription,
  StripePaymentStatus,
  StripeSubscriptionStatus,
} from "types/subscription";

export enum CheckoutStatus {
  COMPLETE = "complete",
  EXPIRED = "expired",
  OPEN = "open",
}

export enum PaymentStatus {
  PAID = "paid",
  UNPAID = "unpaid",
  NO_PAYMENT_REQUIRED = "no_payment_required",
}

export interface ICreateSubscriptionCheckoutPayload extends IActionProps {
  productId: string;
}

export interface ICreateSubscriptionCheckoutData {
  clientSecret: string;
}

export interface IGetCheckoutSessionStatusData {
  status: CheckoutStatus;
  paymentStatus: PaymentStatus;
}

export interface IGetSubscriptionPlansResponse {
  subscriptionPlans: {
    productId: string;
    priceId: string;
    price: number;
    priceCurrency: string;
    priceRecurring?: string;
    priceRecurringAmount?: number;
    description?: string;
    features: { name: string }[];
    name: string;
    statementDescriptor?: string;
    unitLabel?: string;
  }[];
}

export interface IPaymentMethodResponseData {
  card: {
    last4: string;
    expMonth: number;
    expYear: number;
  } | null;
  type: string;
}

export interface IGetSubscriptionData {
  status: StripeSubscriptionStatus;
  periodEnd: number;
  paymentMethod: IPaymentMethodResponseData | null;
}

export interface IGetSubscriptionResponse {
  subscription: IUserSubscription;
}

export interface IGetTransactionsListPayload extends IActionProps {
  limit?: number;
  startingAfter?: string;
}

export interface IGetTransactionResponseData {
  id: string;
  amount: number;
  currency: string;
  date: number;
  status: StripePaymentStatus;
}

export interface IGetTransactionsListResponseData {
  transactions: IGetTransactionResponseData[];
  hasMore: boolean;
}

export interface IGetTransactionsListResponse {
  transactions: ITransaction[];
  hasMore: boolean;
}
