import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";

import { INIT_PAGE } from "constants/index";
import { IProductToggleIsActivePayload, IProductsState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import {
  createProduct,
  getProductActiveList,
  getProductsList,
  updateProduct,
} from "./actions";

const initialState: IProductsState = {
  error: null,
  loading: false,
  mutationLoading: false,
  products: [],
  count: 0,
  activeProducts: [],
  activeLoading: false,
  activeHasMore: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
    toggleIsActive(
      state,
      action: PayloadAction<IProductToggleIsActivePayload>
    ) {
      const { id } = action.payload;
      state.products = state.products.map((product) =>
        product.id === id
          ? { ...product, isActive: !product.isActive }
          : product
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // getProductsList
    builder.addCase(getProductsList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.products = payload.products;
      state.count = payload.count;
    });
    builder.addCase(getProductsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProductsList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    // getProductActiveList
    builder.addCase(getProductActiveList.fulfilled, (state, { payload }) => {
      state.activeLoading = false;
      state.error = null;
      state.activeProducts =
        payload.page === INIT_PAGE
          ? payload.products
          : [...state.activeProducts, ...payload.products];
      state.activeHasMore = payload.hasMore;
    });
    builder.addCase(getProductActiveList.rejected, (state, { payload }) => {
      state.activeLoading = false;
      state.error = payload || null;
    });
    builder.addCase(getProductActiveList.pending, (state) => {
      state.activeLoading = true;
      state.error = null;
    });
    // Matchers
    builder.addMatcher(
      isAnyOf(createProduct.fulfilled, updateProduct.fulfilled),
      (state) => {
        state.mutationLoading = false;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createProduct.pending, updateProduct.pending),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createProduct.rejected, updateProduct.rejected),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
  },
});

export const { clearError, toggleIsActive } = productsSlice.actions;

export default productsSlice.reducer;
