import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { IUsersState } from "types/store";
import {
  createUser,
  deleteUser,
  getUserList,
  resetUserPassword,
  updateUser,
} from "./actions";

const initialState: IUsersState = {
  error: null,
  loading: false,
  mutationLoading: false,
  additionalLoading: false,
  users: [],
  count: 0,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.users = payload.users;
      state.count = payload.count;
    });
    builder.addMatcher(isAnyOf(getUserList.pending), (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getUserList.rejected), (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addMatcher(
      isAnyOf(createUser.pending, updateUser.pending, deleteUser.pending),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(createUser.rejected, updateUser.rejected, deleteUser.rejected),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(
      isAnyOf(createUser.fulfilled, updateUser.fulfilled, deleteUser.fulfilled),
      (state) => {
        state.mutationLoading = false;
        state.error = null;
      }
    );
    builder.addMatcher(isAnyOf(resetUserPassword.pending), (state) => {
      state.additionalLoading = true;
      state.error = null;
    });
    builder.addMatcher(
      isAnyOf(resetUserPassword.rejected),
      (state, { payload }) => {
        state.additionalLoading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(isAnyOf(resetUserPassword.fulfilled), (state) => {
      state.additionalLoading = false;
      state.error = null;
    });
  },
});

export const { clearError } = usersSlice.actions;

export default usersSlice.reducer;
