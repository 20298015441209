import { apiInstance, formatRequestError } from "utils";
import {
  IOrganizationUsersListPayload,
  IOrganizationUsersListResponse,
  IOrganizationUsersListResponseData,
} from "types/api/organizations-users";
import { formatOrganizationUser } from "utils/organizations-users";

export const getOrganizationUsersListService = async (
  args?: IOrganizationUsersListPayload
): Promise<IOrganizationUsersListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: IOrganizationUsersListResponseData } =
      await apiInstance.get("profile/users", {
        params: rest,
      });

    return {
      users: data.users.map((item) => formatOrganizationUser(item)),
      count: data.metadata.itemsAmount,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
