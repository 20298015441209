import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { t } from "i18next";

import { toaster } from "constants/index";
import {
  createUserService,
  deleteUserService,
  getUserListService,
  resetUserPasswordService,
  updateUserService,
} from "services/users";
import { IThunkAPI } from "types/common";
import {
  ICreateUserPayload,
  IDeleteUserPayload,
  IResetUserPasswordPayload,
  IUpdateUserPayload,
  IUserListPayload,
  IUserListResponse,
} from "types/api/users";

export const getUserList = createAsyncThunk<
  IUserListResponse,
  IUserListPayload | undefined,
  IThunkAPI
>("getUserList", async (args, thunkAPI) => {
  try {
    const data = await getUserListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createUser = createAsyncThunk<void, ICreateUserPayload, IThunkAPI>(
  "createUser",
  async (payload, thunkAPI) => {
    try {
      await createUserService(payload);

      if (payload?.onSuccess) {
        payload.onSuccess();
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateUser = createAsyncThunk<void, IUpdateUserPayload, IThunkAPI>(
  "updateUser",
  async (payload, thunkAPI) => {
    try {
      await updateUserService(payload);

      if (payload?.onSuccess) {
        payload.onSuccess();
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const resetUserPassword = createAsyncThunk<
  void,
  IResetUserPasswordPayload,
  IThunkAPI
>("resetUserPassword", async (payload, thunkAPI) => {
  try {
    await resetUserPasswordService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    toast.success(t("messages.resetPasswordLinkSent"), { style: toaster });
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteUser = createAsyncThunk<void, IDeleteUserPayload, IThunkAPI>(
  "deleteUser",
  async (payload, thunkAPI) => {
    try {
      await deleteUserService(payload);

      if (payload?.onSuccess) {
        payload.onSuccess();
      }
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
