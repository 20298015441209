import { createSlice } from "@reduxjs/toolkit";

import { IOrganizationsUsersState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import { getOrganizationUsersList } from "./actions";

const initialState: IOrganizationsUsersState = {
  error: null,
  loading: false,
  users: [],
  count: 0,
};

const organizationsUsersSlice = createSlice({
  name: "organizationsUsers",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // getOrganizationUsersList
    builder.addCase(
      getOrganizationUsersList.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.users = payload.users;
        state.count = payload.count;
      }
    );
    builder.addCase(getOrganizationUsersList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrganizationUsersList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
  },
});

export const { clearError } = organizationsUsersSlice.actions;

export default organizationsUsersSlice.reducer;
