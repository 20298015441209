import { lazy } from "react";

import { PATH_AUTH, PATH_MAIN, ROOT_AUTH } from "constants/index";
import { AuthLayout, MainLayout, UserLayout } from "layout";
import Loadable from "components/Loadable";

// auth
const LoginPage = Loadable(lazy(() => import("pages/auth/login/LoginPage")));
const SignUpPage = Loadable(lazy(() => import("pages/auth/signup/SignUpPage")));
const ResetPasswordPage = Loadable(
  lazy(() => import("pages/auth/reset-password/ResetPasswordPage"))
);
const SetNewPasswordPage = Loadable(
  lazy(() => import("pages/auth/set-new-password/SetNewPasswordPage"))
);

// select plan
const SelectPlanPage = Loadable(
  lazy(() => import("pages/select-plan/SelectPlanPage"))
);

const ProfilePage = Loadable(lazy(() => import("pages/profile/ProfilePage")));
const RootPage = Loadable(lazy(() => import("pages/root/RootPage")));
const UsersPage = Loadable(lazy(() => import("pages/users/UsersPage")));
const CreateClientPage = Loadable(
  lazy(() => import("pages/create-client/CreateClientPage"))
);
const BrandsPage = Loadable(lazy(() => import("pages/brands/BrandsPage")));
const ProductsPage = Loadable(
  lazy(() => import("pages/products/ProductsPage"))
);
const ProceduresPage = Loadable(
  lazy(() => import("pages/procedures/ProceduresPage"))
);
const SubscriptionPage = Loadable(
  lazy(() => import("pages/subscription/SubscriptionPage"))
);
const DashboardPage = Loadable(
  lazy(() => import("pages/dashboard/DashboardPage"))
);
const ClientProfilePage = Loadable(
  lazy(() => import("pages/client-profile/ClientProfilePage"))
);
const RegimenBuilderPage = Loadable(
  lazy(() => import("pages/regimen-builder/RegimenBuilderPage"))
);
const TemplatesPage = Loadable(
  lazy(() => import("pages/templates/TemplatesPage"))
);
const TemplateBuilderPage = Loadable(
  lazy(() => import("pages/template-builder/TemplateBuilderPage"))
);
const SelectTemplatePage = Loadable(
  lazy(() => import("pages/select-template/SelectTemplatePage"))
);

// common
const Page404 = Loadable(lazy(() => import("pages/common/404/NotFoundPage")));

const MainRoutes = {
  path: PATH_MAIN.ROOT,
  children: [
    {
      path: ROOT_AUTH,
      element: <AuthLayout />,
      children: [
        { path: ROOT_AUTH, element: <LoginPage /> },
        { path: PATH_AUTH.LOGIN, element: <LoginPage /> },
        { path: PATH_AUTH.REGISTER, element: <SignUpPage /> },
        { path: PATH_AUTH.RESET_PASSWORD, element: <ResetPasswordPage /> },
        { path: PATH_AUTH.SET_NEW_PASSWORD, element: <SetNewPasswordPage /> },
      ],
    },
    {
      path: PATH_MAIN.ROOT,
      element: <MainLayout />,
      children: [
        {
          path: PATH_MAIN.SELECT_PLAN,
          element: <SelectPlanPage />,
        },
        {
          path: PATH_MAIN.ROOT,
          element: <UserLayout />,
          children: [
            { path: PATH_MAIN.PROFILE, element: <ProfilePage /> },
            { path: PATH_MAIN.ROOT, element: <RootPage /> },
            { path: PATH_MAIN.USERS, element: <UsersPage /> },
            { path: PATH_MAIN.CREATE_CLIENT, element: <CreateClientPage /> },
            { path: PATH_MAIN.EDIT_CLIENT, element: <CreateClientPage /> },
            { path: PATH_MAIN.BRANDS, element: <BrandsPage /> },
            { path: PATH_MAIN.PRODUCTS, element: <ProductsPage /> },
            { path: PATH_MAIN.PROCEDURES, element: <ProceduresPage /> },
            { path: PATH_MAIN.SUBSCRIPTION, element: <SubscriptionPage /> },
            { path: PATH_MAIN.DASHBOARD, element: <DashboardPage /> },
            { path: PATH_MAIN.CLIENT_PROFILE, element: <ClientProfilePage /> },
            { path: PATH_MAIN.NEW_REGIMEN, element: <RegimenBuilderPage /> },
            { path: PATH_MAIN.EDIT_REGIMEN, element: <RegimenBuilderPage /> },
            { path: PATH_MAIN.TEMPLATES, element: <TemplatesPage /> },
            {
              path: PATH_MAIN.SELECT_TEMPLATE,
              element: <SelectTemplatePage />,
            },
            {
              path: PATH_MAIN.CREATE_TEMPLATE,
              element: <TemplateBuilderPage />,
            },
            { path: PATH_MAIN.EDIT_TEMPLATE, element: <TemplateBuilderPage /> },
          ],
        },
      ],
    },
    { path: "*", element: <Page404 /> },
  ],
};

export default MainRoutes;
