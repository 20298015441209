import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.7919 14.7217C76.3209 5.52357 63.611 0.42146 50.4089 0.517946C37.2068 0.614431 24.5728 5.90177 15.2373 15.2373C5.90177 24.5728 0.614431 37.2068 0.517946 50.4089C0.42146 63.611 5.52357 76.3209 14.7217 85.7919C21.1439 92.2066 29.1813 96.7651 37.9828 98.9846C46.7843 101.204 56.0222 101.002 64.7182 98.4C65.9596 98.0214 67.0006 97.1665 67.6133 96.0223C68.2259 94.8781 68.3605 93.5378 67.9875 92.2947C67.6144 91.0516 66.7642 90.0068 65.6228 89.389C64.4814 88.7711 63.1418 88.6305 61.897 88.9979C58.1206 90.1293 54.1991 90.7027 50.2568 90.7001C27.9561 90.7001 9.81351 72.5575 9.81351 50.2568C9.81351 27.9561 27.9561 9.81351 50.2568 9.81351C72.5575 9.81351 90.7001 27.9561 90.7001 50.2568C90.7142 58.14 88.4137 65.854 84.0839 72.4417C83.388 73.5294 83.1488 74.8475 83.4181 76.1104C83.6875 77.3732 84.4436 78.4791 85.5226 79.1883C86.6017 79.8975 87.9167 80.1529 89.1828 79.8992C90.4489 79.6455 91.5639 78.9031 92.2864 77.8328C98.6221 68.1693 101.435 56.618 100.252 45.1233C99.0696 33.6287 93.9629 22.8924 85.7919 14.7217Z"
        fill="#B6B6B6"
      />
      <path
        d="M72.9402 27.5738C72.4844 27.1179 71.9433 26.7562 71.3477 26.5095C70.7521 26.2627 70.1138 26.1357 69.4691 26.1357C68.8245 26.1357 68.1861 26.2627 67.5905 26.5095C66.995 26.7562 66.4538 27.1179 65.9981 27.5738L50.2566 43.3152L34.5151 27.5738C34.0593 27.1179 33.5182 26.7564 32.9226 26.5097C32.327 26.263 31.6887 26.136 31.0441 26.136C30.3994 26.136 29.7611 26.263 29.1655 26.5097C28.57 26.7564 28.0288 27.1179 27.573 27.5738C27.1172 28.0296 26.7556 28.5707 26.5089 29.1663C26.2622 29.7619 26.1353 30.4002 26.1353 31.0448C26.1353 31.6895 26.2622 32.3278 26.5089 32.9233C26.7556 33.5189 27.1172 34.0601 27.573 34.5159L43.3145 50.2573L27.573 65.9988C26.6524 66.9194 26.1353 68.168 26.1353 69.4699C26.1353 70.7718 26.6524 72.0203 27.573 72.9409C28.4936 73.8615 29.7422 74.3787 31.0441 74.3787C32.346 74.3787 33.5945 73.8615 34.5151 72.9409L50.2566 57.1995L65.9981 72.9409C66.9186 73.8615 68.1672 74.3787 69.4691 74.3787C70.771 74.3787 72.0196 73.8615 72.9402 72.9409C73.8608 72.0203 74.3779 70.7718 74.3779 69.4699C74.3779 68.168 73.8608 66.9194 72.9402 65.9988L57.1967 50.2573L72.9402 34.5159C73.3961 34.0601 73.7577 33.519 74.0045 32.9234C74.2512 32.3278 74.3782 31.6895 74.3782 31.0448C74.3782 30.4002 74.2512 29.7618 74.0045 29.1663C73.7577 28.5707 73.3961 28.0296 72.9402 27.5738Z"
        fill="#B6B6B6"
      />
    </svg>
  );
};
export default React.memo(Icon);
