import { createAsyncThunk } from "@reduxjs/toolkit";

import { IThunkAPI } from "types/common";
import {
  ICreateProductPayload,
  IProductsListPayload,
  IProductsListResponse,
  IUpdateProductPayload,
} from "types/api/products";
import {
  createProductService,
  getProductsListService,
  updateProductService,
} from "services/products";

export const getProductsList = createAsyncThunk<
  IProductsListResponse,
  IProductsListPayload | undefined,
  IThunkAPI
>("getProductsList", async (args, thunkAPI) => {
  try {
    const data = await getProductsListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProductActiveList = createAsyncThunk<
  IProductsListResponse,
  IProductsListPayload | undefined,
  IThunkAPI
>("getProductActiveList", async (args, thunkAPI) => {
  try {
    const data = await getProductsListService({ ...args, isActive: true });

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createProduct = createAsyncThunk<
  void,
  ICreateProductPayload,
  IThunkAPI
>("createProduct", async (payload, thunkAPI) => {
  try {
    await createProductService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateProduct = createAsyncThunk<
  void,
  IUpdateProductPayload,
  IThunkAPI
>("updateProduct", async (payload, thunkAPI) => {
  try {
    await updateProductService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
