import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { useAppDispatch } from "store";
import { getProfile } from "store/reducers/user/actions";
import { user as userSelector } from "store/selectors";
import AuthGuard from "layout/guards/AuthGuard";
import Loader from "components/Loader";

import * as Styled from "./styles";

const MainLayout = () => {
  const dispatch = useAppDispatch();
  const { loading } = useSelector(userSelector);

  useEffect(() => {
    if (loading) return;
    dispatch(getProfile());
  }, []);

  if (loading) {
    return (
      <Styled.LoadingWrapper>
        <Loader />
      </Styled.LoadingWrapper>
    );
  }

  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  );
};

export default MainLayout;
