import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.37874 11.6868C5.27829 11.7804 5.14213 11.833 5.00017 11.833C4.8582 11.833 4.72204 11.7804 4.6216 11.6868L0.335883 7.68678C0.28325 7.64101 0.241034 7.58581 0.211754 7.52447C0.182474 7.46314 0.166731 7.39693 0.165462 7.3298C0.164192 7.26266 0.177423 7.19597 0.204367 7.13372C0.231311 7.07146 0.271415 7.0149 0.322285 6.96742C0.373156 6.91994 0.433751 6.88251 0.500457 6.85737C0.567163 6.83222 0.638612 6.81987 0.710542 6.82105C0.782473 6.82224 0.853412 6.83693 0.919126 6.86426C0.984839 6.89159 1.04398 6.93099 1.09303 6.98011L4.46446 10.1268V0.66678C4.46446 0.534172 4.5209 0.406995 4.62136 0.313227C4.72183 0.219459 4.85809 0.16678 5.00017 0.16678C5.14225 0.16678 5.27851 0.219459 5.37898 0.313227C5.47944 0.406995 5.53588 0.534172 5.53588 0.66678V10.1268L8.90731 6.98011C8.95636 6.93099 9.0155 6.89159 9.08121 6.86426C9.14693 6.83693 9.21787 6.82224 9.2898 6.82105C9.36173 6.81987 9.43318 6.83222 9.49988 6.85737C9.56659 6.88251 9.62718 6.91994 9.67805 6.96742C9.72892 7.0149 9.76903 7.07146 9.79597 7.13372C9.82291 7.19597 9.83615 7.26266 9.83488 7.3298C9.83361 7.39693 9.81786 7.46314 9.78858 7.52447C9.7593 7.58581 9.71709 7.64101 9.66446 7.68678L5.37874 11.6868Z"
        fill="black"
      />
    </svg>
  );
};
export default React.memo(Icon);
