import styled from "styled-components";
import { SVGIcon } from "components/svg-icon";

import { breakpoints, colors, spacing, layout, font } from "theme";

export const HeaderContainer = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing[3]}px ${layout.padding.normal};
  background-color: ${colors.secondary};
  position: fixed;
  width: 100%;
  height: 67px;
  top: 0;
  @media (max-width: ${breakpoints.xl}) {
    padding: ${spacing[3]}px ${layout.padding.tablet};
  }
`;

export const Logo = styled(SVGIcon)`
  cursor: pointer;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  margin-right: 15px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border-radius: 5px;
  background-color: ${colors.white};

  h4 {
    color: ${colors.secondary};
    text-transform: uppercase;
  }

  &:hover {
    background-color: ${colors.lightRed};
    h4 {
      color: ${colors.white};
    }
  }
`;

export const Name = styled.h4`
  color: ${colors.white};
  text-transform: capitalize;
`;

export const SmallText = styled.p`
  font-size: ${font.size.smallPlus};
  line-height: 19.5px;
  font-family: ${font.family.primary.light};
  text-transform: capitalize;
  color: ${colors.white};
`;

export const QuestionMarkContainer = styled.div`
  border-radius: 50%;
  background-color: ${colors.white};
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 60px;

  &:hover {
    background-color: ${colors.lightRed};
  }
`;
