import { Button as ButtonComponent } from "components/button";
import { Headline } from "components/headline";
import styled from "styled-components";
import { breakpoints, colors, font } from "theme";

export const Container = styled.div`
  position: relative;
  padding: 20px;
  width: 714px;

  @media (max-width: ${breakpoints.md}) {
    width: 95vw;
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

export const Title = styled(Headline)`
  font-size: ${font.size.large};
`;

export const Button = styled(ButtonComponent)`
  min-width: 139px;
`;

export const ActionButton = styled(ButtonComponent)`
  min-width: 149px;
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
  min-height: 405px;
`;

export const SettingsRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
`;

export const LeftContainer = styled.div`
  min-width: 279px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ImageDescription = styled.div`
  margin-top: 10px;
  font-size: ${font.size.xxsmall};
  font-family: ${font.family.primary.light};
  color: ${colors.black};

  ul {
    padding-inline-start: 20px;
    margin: 0;
  }
`;

export const ColorPickerContainer = styled.div`
  flex-basis: calc(50% - 10px);
`;

export const ColorTitle = styled.p`
  font-size: ${font.size.normalPlus};
  font-family: ${font.family.primary.medium};
  margin-bottom: 10px;
`;

export const ExapmleButton = styled(ButtonComponent)`
  font-size: ${font.size.normal};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
`;

export const CropperButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const CropperContainer = styled.div`
  margin: 20px;
  max-width: 500px;
`;
