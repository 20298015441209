import { createAsyncThunk } from "@reduxjs/toolkit";

import { IThunkAPI } from "types/common";
import {
  ICreateProcedurePayload,
  IProceduresListPayload,
  IProceduresListResponse,
  IUpdateProcedurePayload,
} from "types/api/procedures";
import {
  createProcedureService,
  getProceduresListService,
  updateProcedureService,
} from "services/procedures";

export const getProceduresList = createAsyncThunk<
  IProceduresListResponse,
  IProceduresListPayload | undefined,
  IThunkAPI
>("getProceduresList", async (args, thunkAPI) => {
  try {
    const data = await getProceduresListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProceduresActiveList = createAsyncThunk<
  IProceduresListResponse,
  IProceduresListPayload | undefined,
  IThunkAPI
>("getProceduresActiveList", async (args, thunkAPI) => {
  try {
    const data = await getProceduresListService({ ...args, isActive: true });

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createProcedure = createAsyncThunk<
  void,
  ICreateProcedurePayload,
  IThunkAPI
>("createProcedure", async (payload, thunkAPI) => {
  try {
    await createProcedureService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateProcedure = createAsyncThunk<
  void,
  IUpdateProcedurePayload,
  IThunkAPI
>("updateProcedure", async (payload, thunkAPI) => {
  try {
    await updateProcedureService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
