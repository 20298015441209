import * as React from "react";

import { colors } from "theme";

import { icons, IconTypes } from "./icons";

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconTypes;
}

export const SVGIcon = ({ name, ...rest }: IconProps): JSX.Element => {
  const Icon = icons[name] || icons.logo;
  return <Icon width={24} height={24} color={colors.primary} {...rest} />;
};
