import styled from "styled-components";
import { Button as ButtonComponent } from "components/button";
import { colors, font } from "theme";
import { Headline } from "components/headline";

export const Container = styled.div`
  padding: 40px;
  max-width: 422px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(Headline)`
  font-size: ${font.size.large};
`;

export const SubTitle = styled.p`
  padding-bottom: 40px;
  padding-top: 20px;
  text-align: center;
  font-size: ${font.size.normal};
  line-height: 21px;

  & > a {
    border-bottom: 1px ${colors.secondary} solid;
    text-decoration: none;
    color: ${colors.secondary};
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled(ButtonComponent)`
  min-width: 139px;
`;
