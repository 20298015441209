import { ToastContainerProps } from "react-toastify";
import { SVGIcon } from "components/svg-icon";
import { colors } from "theme";

export const toaster = {
  background: "white",
  color: "black",
  borderRadius: "50px",
  border: "1px solid rgb(242, 115, 135)",
  width: "350px",
};

export const toastProps: ToastContainerProps = {
  icon: <SVGIcon name="info" color={colors.deepBlush} />,
  closeButton: false,
  hideProgressBar: true,
  position: "bottom-left",
};
