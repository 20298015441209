import { Store } from "@reduxjs/toolkit";
import axios from "axios";

import { API_ERRORS, REFRESH_TOKENS_URL } from "constants/index";
import { refreshTokensService } from "services/auth";
import { RootState } from "store";
import { setTokens } from "store/reducers/auth";
import { logout } from "store/reducers/auth/actions";
import { IErrorResponse } from "types/api/common";
import { UserRole } from "types/user";
import { handleError } from "./errors";

let store: Store<RootState>;

export const injectStore = (_store: Store<RootState>) => {
  store = _store;
};

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  timeout: 30000,
});

apiInstance.interceptors.request.use(async (config) => {
  try {
    const token = store.getState().auth.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.log("Error interceptors.request", e);
  } finally {
    return config;
  }
});

apiInstance.interceptors.response.use(
  (config) => config,
  async (error) => {
    try {
      const { dispatch } = store;
      const originalRequest = error.config;
      const userRole = store.getState().user.user?.role;

      let errorCode = "";
      let errorResponse: IErrorResponse | null = null;

      if (error?.response?.data instanceof ArrayBuffer) {
        try {
          const textDecoder = new TextDecoder();
          const errorData = textDecoder.decode(error.response.data);

          errorResponse = JSON.parse(errorData);
          errorCode = JSON.parse(errorData)?.error;
        } catch (error) {}
      } else {
        errorResponse = error.response?.data || null;
        errorCode = error.response?.data?.error || "";
      }

      if (error.response?.status === 401) {
        if (
          [
            API_ERRORS.jwtExpired,
            API_ERRORS.invalidJwt,
            API_ERRORS.unauthorized,
          ].includes(errorCode) &&
          !originalRequest._retry &&
          !originalRequest.url?.includes(REFRESH_TOKENS_URL)
        ) {
          originalRequest._retry = true;
          const refreshToken = store.getState().auth.refreshToken;
          if (!refreshToken) {
            dispatch(logout());
            return;
          }

          const tokens = await refreshTokensService({
            refreshToken,
            remember: true,
            onError: () => {
              dispatch(logout());
            },
          });

          dispatch(
            setTokens({
              accessToken: tokens.accessToken,
              refreshToken: tokens.refreshToken,
            })
          );

          originalRequest.headers.Authorization = `Bearer ${tokens.accessToken}`;
          return axios(originalRequest);
        }
      }

      if (
        userRole !== UserRole.OWNER &&
        [
          API_ERRORS.userIsInactive,
          API_ERRORS.organizationIsInactive,
          API_ERRORS.userIsUnsubscribed,
        ].includes(errorCode)
      ) {
        dispatch(logout());
      }

      handleError(errorCode, errorResponse, userRole);

      return Promise.reject(error);
    } catch (e) {
      console.log("Error interceptors.response", e);
      return Promise.reject(error);
    }
  }
);
