export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getEnumValue = <T extends object>(
  enumObj: T,
  value: string,
  defaultValue: T[keyof T]
): T[keyof T] =>
  (Object.values(enumObj) as unknown as string[]).includes(value)
    ? (value as T[keyof T])
    : defaultValue;
