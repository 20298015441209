import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";

import { IClientState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import {
  createClient,
  deleteClient,
  getClient,
  getRegimenList,
  getRegimenPDF,
  updateClient,
} from "./actions";

const initialState: IClientState = {
  error: null,
  loading: false,
  mutationLoading: false,
  client: null,
  regimens: [],
  count: 0,
  regimensLoading: false,
  pdfLoadingId: null,
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
    setPDFLoadingId(state, action: PayloadAction<string>) {
      state.pdfLoadingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addCase(getClient.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.client = payload.client;
    });
    builder.addCase(deleteClient.fulfilled, (state) => {
      state.mutationLoading = false;
      state.error = null;
    });
    // getRegimenPDF
    builder.addCase(getRegimenPDF.fulfilled, (state) => {
      state.pdfLoadingId = null;
      state.error = null;
    });
    builder.addCase(getRegimenPDF.rejected, (state, { payload }) => {
      state.pdfLoadingId = null;
      state.error = payload || null;
    });
    // getRegimenList
    builder.addCase(getRegimenList.fulfilled, (state, { payload }) => {
      state.regimensLoading = false;
      state.error = null;
      state.regimens = payload.regimens;
      state.count = payload.count;
    });
    builder.addCase(getRegimenList.pending, (state) => {
      state.regimensLoading = true;
      state.error = null;
    });
    builder.addCase(getRegimenList.rejected, (state, { payload }) => {
      state.regimensLoading = false;
      state.error = payload || null;
    });
    // Matchers
    builder.addMatcher(isAnyOf(getClient.pending), (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addMatcher(isAnyOf(getClient.rejected), (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    builder.addMatcher(
      isAnyOf(updateClient.pending, createClient.pending, deleteClient.pending),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        updateClient.rejected,
        createClient.rejected,
        deleteClient.rejected
      ),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(
      isAnyOf(updateClient.fulfilled, createClient.fulfilled),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = null;
        state.client = payload.client;
      }
    );
  },
});

export const { clearError, setPDFLoadingId } = clientSlice.actions;

export default clientSlice.reducer;
