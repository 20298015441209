import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 14.4707V16.2354C4 16.7034 4.18437 17.1523 4.51256 17.4832C4.84075 17.8142 5.28587 18.0001 5.75 18.0001H16.25C16.7141 18.0001 17.1592 17.8142 17.4874 17.4832C17.8156 17.1523 18 16.7034 18 16.2354V14.4707"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.375 9.17637L11 13.5881M11 13.5881L6.625 9.17637M11 13.5881L11 2.9999"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default React.memo(Icon);
