import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { IOrganizationState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import {
  getOrganizationDetails,
  getOrganizationDetailsById,
  getOrganizationExamplePDF,
  updateOrganizationDetails,
} from "./actions";

const initialState: IOrganizationState = {
  error: null,
  loading: false,
  mutationLoading: false,
  pdfLoading: false,
  details: null,
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // updateOrganizationDetails
    builder.addCase(
      updateOrganizationDetails.fulfilled,
      (state, { payload }) => {
        state.mutationLoading = false;
        state.details = payload.details;
      }
    );
    builder.addCase(updateOrganizationDetails.pending, (state) => {
      state.mutationLoading = true;
      state.error = null;
    });
    builder.addCase(
      updateOrganizationDetails.rejected,
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
    // getOrganizationExamplePDF
    builder.addCase(
      getOrganizationExamplePDF.fulfilled,
      (state, { payload }) => {
        state.pdfLoading = false;
        state.error = null;
      }
    );
    builder.addCase(getOrganizationExamplePDF.pending, (state) => {
      state.pdfLoading = true;
      state.error = null;
    });
    builder.addCase(
      getOrganizationExamplePDF.rejected,
      (state, { payload }) => {
        state.pdfLoading = false;
        state.error = payload || null;
      }
    );
    // getOrganizationDetails
    // getOrganizationDetailsById
    builder.addMatcher(
      isAnyOf(
        getOrganizationDetails.pending,
        getOrganizationDetailsById.pending
      ),
      (state) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getOrganizationDetails.fulfilled,
        getOrganizationDetailsById.fulfilled
      ),
      (state, { payload }) => {
        state.loading = false;
        state.details = payload.details;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getOrganizationDetails.rejected,
        getOrganizationDetailsById.rejected
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      }
    );
  },
});

export const { clearError } = organizationSlice.actions;

export default organizationSlice.reducer;
