import React from "react";
import Loader from "components/Loader";

import * as Styled from "./styles";

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
  className?: string;
}

const PageLayout = ({ children, loading, className }: Props) => {
  return (
    <Styled.Container className={className}>
      {loading ? (
        <Styled.LoaderContainer>
          <Loader />
        </Styled.LoaderContainer>
      ) : (
        children
      )}
    </Styled.Container>
  );
};

export default PageLayout;
