import logo from "./logo";
import eye from "./eye";
import eyeCrossed from "./eyeCrossed";
import check from "./check";
import checkoutSuccess from "./checkout/success";
import checkoutError from "./checkout/error";
import questionMark from "./questionMark";
import info from "./info";
import user from "./user";
import edit from "./edit";
import remove from "./remove";
import search from "./search";
import close from "./close";
import chevronDown from "./chevronDown";
import arrowDown from "./arrowDown";
import triangleArrowRight from "./triangleArrowRight";
import copy from "./copy";
import download from "./download";
import cross from "./cross";
import arrows from "./arrows";

export const icons = {
  logo,
  eye,
  eyeCrossed,
  check,
  checkoutSuccess,
  checkoutError,
  questionMark,
  info,
  user,
  edit,
  remove,
  search,
  close,
  chevronDown,
  arrowDown,
  triangleArrowRight,
  copy,
  download,
  cross,
  arrows,
};

export type IconTypes = keyof typeof icons;
