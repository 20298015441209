import { ISubscription } from "types/subscription";
import { UserRole } from "types/user";

export const SUBSCRIPTION_PLANS: ISubscription[] = [
  {
    id: "1",
    price: 299,
    title: "selectPlan.forSolo",
    list: ["selectPlan.fullAccess", "selectPlan.onlyOne"],
    role: UserRole.SOLO_PRACTITIONER,
  },
  {
    id: "2",
    price: 499,
    title: "selectPlan.forTeams",
    list: ["selectPlan.fullAccess", "selectPlan.unlimitedUsers"],
    role: UserRole.ORGANIZATION_ADMIN,
  },
];

export const SESSION_ID_PARAM = "session_id";

export const SUBSCRIPTION_TYPES = {
  [UserRole.ORGANIZATION_ADMIN]: "team",
  [UserRole.SOLO_PRACTITIONER]: "solo",
};
