import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.86603 6.5C4.48113 7.16667 3.51887 7.16667 3.13397 6.5L0.535898 2C0.150998 1.33333 0.632123 0.499999 1.40192 0.499999L6.59808 0.499999C7.36788 0.499999 7.849 1.33333 7.4641 2L4.86603 6.5Z"
        fill="#121212"
      />
    </svg>
  );
};
export default React.memo(Icon);
