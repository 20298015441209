import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_36_2352)">
        <path
          d="M98.0937 35.6172C97.717 34.3822 96.8665 33.3466 95.7282 32.7371C94.59 32.1276 93.2566 31.9937 92.0199 32.3648C90.7832 32.7359 89.7438 33.5817 89.1292 34.7172C88.5145 35.8527 88.3747 37.1855 88.7402 38.4238C89.8641 42.1797 90.4333 46.0796 90.4296 50C90.4296 72.1855 72.3808 90.2344 50.1952 90.2344C28.0097 90.2344 9.96087 72.1855 9.96087 50C9.96087 27.8145 28.0097 9.76563 50.1952 9.76563C58.0378 9.75158 65.7118 12.0402 72.2655 16.3477C72.8012 16.7093 73.4033 16.9609 74.037 17.0881C74.6706 17.2152 75.3232 17.2153 75.9569 17.0883C76.5906 16.9613 77.1928 16.7098 77.7285 16.3483C78.2643 15.9868 78.7229 15.5226 79.0779 14.9825C79.4329 14.4424 79.6771 13.8372 79.7964 13.2021C79.9157 12.5669 79.9077 11.9143 79.7729 11.2823C79.6381 10.6502 79.3792 10.0512 79.0112 9.51992C78.6431 8.98868 78.1732 8.53578 77.6288 8.1875C67.2808 1.3932 54.7803 -1.32084 42.5465 0.570656C30.3127 2.46215 19.2155 8.82467 11.4026 18.4269C3.58971 28.0291 -0.38337 40.1882 0.252288 52.551C0.887947 64.9138 6.08715 76.6013 14.8437 85.3516C22.7211 93.2139 32.9959 98.2241 44.0415 99.5892C55.0872 100.954 66.2725 98.5962 75.8271 92.8882C85.3816 87.1803 92.7593 78.4486 96.7923 68.0753C100.825 57.7021 101.283 46.28 98.0937 35.6172Z"
          fill="#366AB3"
        />
        <path
          d="M72.914 32.8747L42.871 62.9254L27.4726 47.527C27.0191 47.0735 26.4807 46.7138 25.8883 46.4684C25.2958 46.223 24.6607 46.0967 24.0194 46.0967C23.3781 46.0967 22.7431 46.223 22.1506 46.4684C21.5581 46.7138 21.0198 47.0735 20.5663 47.527C20.1128 47.9805 19.7531 48.5188 19.5077 49.1113C19.2623 49.7038 19.136 50.3388 19.136 50.9801C19.136 51.6214 19.2623 52.2565 19.5077 52.849C19.7531 53.4414 20.1128 53.9798 20.5663 54.4333L38.0097 71.8747C39.299 73.164 41.0477 73.8883 42.871 73.8883C44.6944 73.8883 46.443 73.164 47.7323 71.8747L79.8202 39.7809C80.736 38.8651 81.2506 37.623 81.2506 36.3278C81.2506 35.0326 80.736 33.7905 79.8202 32.8747C78.9044 31.9588 77.6623 31.4443 76.3671 31.4443C75.0719 31.4443 73.8298 31.9588 72.914 32.8747Z"
          fill="#366AB3"
        />
      </g>
      <defs>
        <clipPath id="clip0_36_2352">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default React.memo(Icon);
