import styled from "styled-components";

import { colors, font } from "theme";

import { ButtonVariant } from ".";

export const ButtonStyled = styled.button<{
  $variant: ButtonVariant;
}>`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;

  text-transform: uppercase;
  size: 14px;
  line-height: 21px;
  font-family: ${font.family.primary.medium};

  cursor: pointer;
  &:disabled {
    cursor: auto;
  }

  ${({ disabled, $variant }) => {
    switch ($variant) {
      case "primary":
        return `
          background-color: ${disabled ? colors.grey : colors.lightRed};
          color:${colors.white};

          &:hover {
            background-color: ${disabled ? colors.grey : colors.red};
          }
        `;
      case "secondary":
        return `
          background-color: ${colors.white};
          color:${disabled ? colors.grey : colors.secondary};
          outline: 1px solid ${colors.solitude};

          &:hover {
            background-color: ${disabled ? colors.white : colors.lightRed};
            color: ${disabled ? colors.grey : colors.white};
            ${disabled ? "outline: 0 px;" : ""};
          }
        `;
      default:
        return `
        background-color: ${disabled ? colors.grey : colors.lightRed};
        color:${colors.white};

        :hover {
          background-color: ${colors.red};
        }
        `;
    }
  }};
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
