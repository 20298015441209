import { createAsyncThunk } from "@reduxjs/toolkit";
import { getClientsListService } from "services/clients";
import { IClientListPayload, IClientListResponse } from "types/api/clients";

import { IThunkAPI } from "types/common";

export const getClientList = createAsyncThunk<
  IClientListResponse,
  IClientListPayload | undefined,
  IThunkAPI
>("getClientList", async (args, thunkAPI) => {
  try {
    const data = await getClientsListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
