import { apiInstance, formatRequestError } from "utils";
import {
  ICreateProcedurePayload,
  IProceduresListPayload,
  IProceduresListResponse,
  IProceduresListResponseData,
  IUpdateProcedurePayload,
} from "types/api/procedures";
import { formatProcedure } from "utils/procedures";

export const getProceduresListService = async (
  args?: IProceduresListPayload
): Promise<IProceduresListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: IProceduresListResponseData } =
      await apiInstance.get("organization/my/procedures", {
        params: rest,
      });

    return {
      procedures: data.procedures.map((item) => formatProcedure(item)),
      count: data.metadata.itemsAmount,
      page: data.metadata.page,
      hasMore: data.metadata.hasNext,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createProcedureService = async ({
  name,
  note,
  isActive = true,
}: ICreateProcedurePayload): Promise<void> => {
  try {
    await apiInstance.post("procedure", {
      name,
      note,
      isActive,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateProcedureService = async ({
  id,
  name,
  note,
  isActive,
}: IUpdateProcedurePayload): Promise<void> => {
  try {
    await apiInstance.patch(`procedure/${id}`, {
      ...(!!name && { name }),
      ...(!!note && { note }),
      ...((isActive === true || isActive === false) && { isActive }),
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
