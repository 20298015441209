import { REFRESH_TOKENS_URL } from "constants/index";
import {
  IAuthResponse,
  IAuthResponseData,
  ILoginPayload,
  IRefreshTokensPayload,
  IResetPasswordPayload,
  ISetNewPasswordPayload,
  ISignUpPayload,
} from "types/api/auth";
import { formatRequestError, apiInstance } from "utils";

export const loginService = async ({
  email,
  password,
  rememberMe,
}: ILoginPayload): Promise<IAuthResponse> => {
  try {
    const { data }: { data: IAuthResponseData } = await apiInstance.post(
      "auth/sign-in",
      {
        email,
        password,
        remember: rememberMe,
      }
    );

    return {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken || "",
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const signUpService = async ({
  email,
  password,
  name,
  organization,
}: ISignUpPayload): Promise<IAuthResponse> => {
  try {
    const { data }: { data: IAuthResponseData } = await apiInstance.post(
      "auth/sign-up",
      {
        fullName: name,
        organizationName: organization,
        email,
        password,
        remember: true,
      }
    );

    return {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken || "",
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const refreshTokensService = async (
  payload: IRefreshTokensPayload
): Promise<IAuthResponse> => {
  try {
    const { data }: { data: IAuthResponseData } = await apiInstance.post(
      `auth/${REFRESH_TOKENS_URL}`,
      {
        remember: payload.remember,
        refreshToken: payload.refreshToken,
      }
    );

    return {
      accessToken: data.accessToken,
      refreshToken: data.refreshToken || "",
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    if (payload.onError) {
      payload.onError();
    }

    throw errorMessage;
  }
};

export const resetPasswordService = async ({
  email,
}: IResetPasswordPayload): Promise<void> => {
  try {
    await apiInstance.post("auth/reset-password", {
      email,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const setNewPasswordService = async ({
  token,
  password,
}: ISetNewPasswordPayload): Promise<void> => {
  try {
    await apiInstance.patch(`auth/reset-password/${token}`, {
      password,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
