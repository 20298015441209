import { RootState } from "store";

export const auth = (state: RootState) => state.auth;
export const user = (state: RootState) => state.user;
export const users = (state: RootState) => state.users;
export const clients = (state: RootState) => state.clients;
export const client = (state: RootState) => state.client;
export const brands = (state: RootState) => state.brands;
export const products = (state: RootState) => state.products;
export const procedures = (state: RootState) => state.procedures;
export const subscription = (state: RootState) => state.subscription;
export const dashboard = (state: RootState) => state.dashboard;
export const regimenBuilder = (state: RootState) => state.regimenBuilder;
export const templates = (state: RootState) => state.templates;
export const organization = (state: RootState) => state.organization;
export const customers = (state: RootState) => state.customers;
export const organizationsUsers = (state: RootState) =>
  state.organizationsUsers;
