import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6665 12.6667V14.5001C12.6665 14.9863 12.4733 15.4526 12.1295 15.7964C11.7857 16.1403 11.3194 16.3334 10.8332 16.3334H3.49984C3.01361 16.3334 2.54729 16.1403 2.20347 15.7964C1.85966 15.4526 1.6665 14.9863 1.6665 14.5001V7.16675C1.6665 6.68052 1.85966 6.2142 2.20347 5.87039C2.54729 5.52657 3.01361 5.33341 3.49984 5.33341H5.33317M7.1665 1.66675H14.4998C15.5124 1.66675 16.3332 2.48756 16.3332 3.50008V10.8334C16.3332 11.8459 15.5124 12.6667 14.4998 12.6667H7.1665C6.15398 12.6667 5.33317 11.8459 5.33317 10.8334V3.50008C5.33317 2.48756 6.15398 1.66675 7.1665 1.66675Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default React.memo(Icon);
