import {
  IClientListPayload,
  IClientListResponse,
  IClientListResponseData,
} from "types/api/clients";
import { apiInstance, formatRequestError } from "utils";
import { formatClient } from "utils/client";

export const getClientsListService = async (
  args?: IClientListPayload
): Promise<IClientListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: IClientListResponseData } = await apiInstance.get(
      "organization/my/clients",
      {
        params: rest,
      }
    );

    return {
      clients: data.clients.map((item) => formatClient(item)),
      count: data.metadata.itemsAmount,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
