import { t } from "i18next";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Dropdown, IOption, OptionType } from "components/dropdown";
import HelpModal from "components/modals/help-modal";
import OrganizationDetailsModal from "components/modals/organization-details-modal";
import { SVGIcon } from "components/svg-icon";
import { PATH_MAIN, ROLE_TITLE } from "constants/index";
import OrgAdminsGuard from "layout/guards/OrgAdminsGuard";
import { useAppDispatch } from "store";
import { logout } from "store/reducers/auth/actions";
import { user as userSelector } from "store/selectors";
import { colors } from "theme";
import { UserRole } from "types/user";
import { shortUserName } from "utils/user";

import * as Styled from "./styles";

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(userSelector);

  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [openOrganizationDetailsModal, setOpenOrganizationDetailsModal] =
    useState<boolean>(false);

  const formattedFullname = useMemo(() => {
    return shortUserName(user?.name || "");
  }, [user]);

  const options: IOption[] = useMemo(() => {
    if (!user?.role) return [];

    const regularOptions = [
      [UserRole.OWNER].includes(user.role) && [
        {
          title: t("common.customers"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.ROOT),
        },
        {
          title: t("common.users"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.USERS),
        },
      ],
      [
        UserRole.ORGANIZATION_ADMIN,
        UserRole.ADMIN,
        UserRole.SOLO_PRACTITIONER,
        UserRole.PRACTITIONER,
      ].includes(user.role) && {
        title: t("common.clients"),
        type: OptionType.BUTTON,
        onClick: () => navigate(PATH_MAIN.ROOT),
      },
      [
        UserRole.ORGANIZATION_ADMIN,
        UserRole.ADMIN,
        UserRole.SOLO_PRACTITIONER,
      ].includes(user.role) && [
        {
          title: "Manage",
          type: OptionType.SEPARATOR as const,
        },
        {
          title: t("common.products"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.PRODUCTS),
        },
        {
          title: t("common.brands"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.BRANDS),
        },
        {
          title: t("common.procedures"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.PROCEDURES),
        },
        {
          title: t("common.templates"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.TEMPLATES),
        },
        {
          type: OptionType.SEPARATOR as const,
        },
      ],
      [UserRole.ORGANIZATION_ADMIN, UserRole.ADMIN].includes(user.role) && {
        title: t("navigation.manageTeam"),
        type: OptionType.BUTTON,
        onClick: () => navigate(PATH_MAIN.USERS),
      },
      {
        title: t("common.profile"),
        type: OptionType.BUTTON,
        onClick: () => navigate(PATH_MAIN.PROFILE),
      },
      [
        UserRole.ORGANIZATION_ADMIN,
        UserRole.ADMIN,
        UserRole.SOLO_PRACTITIONER,
      ].includes(user.role) && [
        {
          title: t("common.dashboard"),
          type: OptionType.BUTTON,
          onClick: () => navigate(PATH_MAIN.DASHBOARD),
        },
        {
          title: t("navigation.logoAndColors"),
          type: OptionType.BUTTON,
          onClick: () => setOpenOrganizationDetailsModal(true),
        },
      ],
      [UserRole.ORGANIZATION_ADMIN, UserRole.SOLO_PRACTITIONER].includes(
        user.role
      ) && {
        title: t("navigation.manageSubscription"),
        type: OptionType.BUTTON,
        onClick: () => navigate(PATH_MAIN.SUBSCRIPTION),
      },
      {
        title: t("common.logout"),
        type: OptionType.BUTTON,
        onClick: () => dispatch(logout()),
      },
    ]
      .flat()
      .filter((o): o is Exclude<typeof o, boolean> => typeof o !== "boolean");

    return regularOptions;
  }, [user]);

  const logoClickHandler = () => {
    navigate(PATH_MAIN.ROOT);
  };

  return (
    <Styled.HeaderContainer>
      <Styled.Logo
        onClick={logoClickHandler}
        name="logo"
        width="115"
        height="43"
        color={colors.white}
      />
      <Styled.RightContainer>
        <Styled.QuestionMarkContainer onClick={() => setOpenHelpModal(true)}>
          <SVGIcon name="questionMark" />
        </Styled.QuestionMarkContainer>
        <Styled.NameContainer>
          <Styled.Name>{formattedFullname}</Styled.Name>
          <Styled.SmallText>
            {!!user?.role && t(ROLE_TITLE[user?.role])}
          </Styled.SmallText>
        </Styled.NameContainer>
        <Dropdown options={options}>
          <Styled.IconContainer>
            <h4>{user?.name ? user.name[0] : ""}</h4>
          </Styled.IconContainer>
        </Dropdown>
      </Styled.RightContainer>

      <HelpModal
        isOpen={openHelpModal}
        onClose={() => setOpenHelpModal(false)}
      />

      <OrgAdminsGuard includeSoloPractitioner>
        <>
          {openOrganizationDetailsModal && (
            <OrganizationDetailsModal
              isOpen={openOrganizationDetailsModal}
              onClose={() => setOpenOrganizationDetailsModal(false)}
            />
          )}
        </>
      </OrgAdminsGuard>
    </Styled.HeaderContainer>
  );
};

export default Header;
