import { IOrganizationDetailsResponseData } from "types/api/organization";
import { IOrganizationDetails } from "types/organization";

export const formatOrganizationDetails = (
  data: IOrganizationDetailsResponseData
): IOrganizationDetails => {
  return {
    contactEmail: data.email,
    contactPhone: data.phone,
    logo: data.logoFileUrl,
    pdfColorHexMorningRoutine: data.pdfColorHexMorningRoutine,
    pdfColorHexEveningRoutine: data.pdfColorHexEveningRoutine,
    pdfColorHexNotes: data.pdfColorHexNotes,
    pdfColorHexFooter: data.pdfColorHexFooter,
  };
};
