import { apiInstance, formatRequestError } from "utils";
import {
  IBrandListResponseData,
  IBrandsListPayload,
  IBrandsListResponse,
  ICreateBrandPayload,
  IDeleteBrandPayload,
  IUpdateBrandPayload,
} from "types/api/brands";
import { formatBrand } from "utils/brands";

export const getBrandsListService = async (
  args?: IBrandsListPayload
): Promise<IBrandsListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: IBrandListResponseData } = await apiInstance.get(
      "organization/my/brands",
      {
        params: rest,
      }
    );

    return {
      brands: data.brands.map((item) => formatBrand(item)),
      count: data.metadata.itemsAmount,
      page: data.metadata.page,
      hasMore: data.metadata.hasNext,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const createBrandService = async ({
  name,
  isActive = true,
}: ICreateBrandPayload): Promise<void> => {
  try {
    await apiInstance.post("brand", {
      name,
      isActive,
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateBrandService = async ({
  id,
  name,
  isActive,
}: IUpdateBrandPayload): Promise<void> => {
  try {
    await apiInstance.patch(`brand/${id}`, {
      ...(!!name && { name }),
      ...((isActive === true || isActive === false) && { isActive }),
    });
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const deleteBrandService = async ({
  id,
}: IDeleteBrandPayload): Promise<void> => {
  try {
    await apiInstance.delete(`brand/${id}`);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
