import { IProductResponseData } from "types/api/products";
import { IProduct } from "types/products";

export const formatProduct = (data: IProductResponseData): IProduct => {
  return {
    id: data.id,
    name: data.name,
    note: data.note,
    isActive: data.isActive,
    brand: {
      id: data.brand.id,
      name: data.brand.name,
      isActive: data.brand.isActive,
    },
  };
};
