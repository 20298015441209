import { IOrganizationUserResponseData } from "types/api/organizations-users";
import { IOrganizationUser } from "types/organizations-users";

export const formatOrganizationUser = (
  data: IOrganizationUserResponseData
): IOrganizationUser => {
  return {
    id: data.id,
    name: data.fullName,
    role: data.role,
    lastActivity: data.lastActivityDate,
    email: data.email,
    organization: {
      name: data.organization?.name || "",
    },
    isActive: data.isActive,
  };
};
