import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getSubscriptionPlansService,
  getSubscriptionService,
  getTransactionsListService,
  getUpdatePaymentDetailsLinkService,
} from "services/subscription";
import {
  IGetSubscriptionPlansResponse,
  IGetSubscriptionResponse,
  IGetTransactionsListPayload,
  IGetTransactionsListResponse,
} from "types/api/subscription";
import { IActionProps, IThunkAPI } from "types/common";

export const getSubscriptionPlans = createAsyncThunk<
  IGetSubscriptionPlansResponse,
  void,
  IThunkAPI
>("getSubscriptionPlans", async (args, thunkAPI) => {
  try {
    const data = await getSubscriptionPlansService();

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSubscription = createAsyncThunk<
  IGetSubscriptionResponse,
  IActionProps | undefined,
  IThunkAPI
>("getSubscription", async (args, thunkAPI) => {
  try {
    const data = await getSubscriptionService();

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTransactionsList = createAsyncThunk<
  IGetTransactionsListResponse,
  IGetTransactionsListPayload,
  IThunkAPI
>("getTransactionsList", async (args, thunkAPI) => {
  try {
    const data = await getTransactionsListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getUpdatePaymentDetailsLink = createAsyncThunk<
  string,
  IActionProps | undefined,
  IThunkAPI
>("getUpdatePaymentDetailsLink", async (args, thunkAPI) => {
  try {
    const data = await getUpdatePaymentDetailsLinkService();

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
