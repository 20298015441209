import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6883 5.375L13.8287 7.5M13.8287 7.5L11.6883 9.625M13.8287 7.5H9.54792M3.12673 5.375L0.986328 7.5M0.986328 7.5L3.12673 9.625M0.986328 7.5H5.26713M5.26713 11.75L7.40753 13.875M7.40753 13.875L9.54792 11.75M7.40753 13.875V9.625M9.54792 3.25L7.40753 1.125M7.40753 1.125L5.26713 3.25M7.40753 1.125V5.375"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default React.memo(Icon);
