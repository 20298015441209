import React, { SVGProps } from "react";

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.968997 0.969364C1.03867 0.899519 1.12143 0.844104 1.21255 0.806295C1.30366 0.768485 1.40135 0.749023 1.5 0.749023C1.59865 0.749023 1.69633 0.768485 1.78745 0.806295C1.87857 0.844104 1.96133 0.899519 2.031 0.969364L6 4.93986L9.969 0.969364C10.0387 0.899632 10.1215 0.844317 10.2126 0.806579C10.3037 0.76884 10.4014 0.749416 10.5 0.749416C10.5986 0.749416 10.6963 0.76884 10.7874 0.806579C10.8785 0.844317 10.9613 0.899632 11.031 0.969364C11.1007 1.0391 11.156 1.12188 11.1938 1.21299C11.2315 1.3041 11.2509 1.40175 11.2509 1.50036C11.2509 1.59898 11.2315 1.69663 11.1938 1.78774C11.156 1.87885 11.1007 1.96163 11.031 2.03136L7.0605 6.00036L11.031 9.96936C11.1007 10.0391 11.156 10.1219 11.1938 10.213C11.2315 10.3041 11.2509 10.4017 11.2509 10.5004C11.2509 10.599 11.2315 10.6966 11.1938 10.7877C11.156 10.8788 11.1007 10.9616 11.031 11.0314C10.9613 11.1011 10.8785 11.1564 10.7874 11.1941C10.6963 11.2319 10.5986 11.2513 10.5 11.2513C10.4014 11.2513 10.3037 11.2319 10.2126 11.1941C10.1215 11.1564 10.0387 11.1011 9.969 11.0314L6 7.06086L2.031 11.0314C1.96127 11.1011 1.87848 11.1564 1.78737 11.1941C1.69626 11.2319 1.59861 11.2513 1.5 11.2513C1.40138 11.2513 1.30373 11.2319 1.21262 11.1941C1.12151 11.1564 1.03873 11.1011 0.968997 11.0314C0.899266 10.9616 0.843951 10.8788 0.806212 10.7877C0.768474 10.6966 0.74905 10.599 0.74905 10.5004C0.74905 10.4017 0.768474 10.3041 0.806212 10.213C0.843951 10.1219 0.899266 10.0391 0.968997 9.96936L4.9395 6.00036L0.968997 2.03136C0.899153 1.9617 0.843738 1.87893 0.805928 1.78781C0.768119 1.6967 0.748657 1.59901 0.748657 1.50036C0.748657 1.40171 0.768119 1.30403 0.805928 1.21291C0.843738 1.1218 0.899153 1.03903 0.968997 0.969364Z"
        fill="#121212"
      />
    </svg>
  );
};
export default React.memo(Icon);
