import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { toastProps } from "constants/index";
import { store, persistor } from "store";
import { injectStore } from "utils";
import router from "router";

import "./locales";

injectStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <RouterProvider router={router} />
        <ToastContainer {...toastProps} />
      </PersistGate>
    </Provider>
  );
}

export default App;
