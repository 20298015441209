import { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { PATH_MAIN } from "constants/index";
import { user as userSelector } from "store/selectors";
import { UserRole } from "types/user";

type GuardProps = {
  roles: UserRole[];
  children: ReactElement | null;
  noRedirect?: boolean;
};

const RoleGuard = ({ children, roles, noRedirect = false }: GuardProps) => {
  const { user, loading } = useSelector(userSelector);
  const navigate = useNavigate();

  const isDenied = useMemo(() => {
    if (!!user && roles.includes(user?.role)) {
      return false;
    }

    return true;
  }, [user]);

  useEffect(() => {
    if (isDenied && !noRedirect) {
      navigate(PATH_MAIN.ROOT, {
        state: {
          from: "",
        },
        replace: true,
      });
    }
  }, [isDenied, navigate]);

  if (loading || isDenied) return null;

  return children;
};

export default RoleGuard;
