function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROOT_AUTH = "/auth";

export const PATH_MAIN = {
  ROOT: "/",
  SELECT_PLAN: "/select-plan",
  PROFILE: "/profile",
  USERS: "/users",
  CREATE_CLIENT: "/create-client",
  EDIT_CLIENT: "/edit-client/:id",
  BRANDS: "/brands",
  PRODUCTS: "/products",
  SUBSCRIPTION: "/subscription",
  PROCEDURES: "/procedures",
  DASHBOARD: "/dashboard",
  CLIENT_PROFILE: "/client-profile/:id",
  NEW_REGIMEN: "/new-regimen",
  EDIT_REGIMEN: "/edit-regimen/:id",
  TEMPLATES: "/templates",
  CREATE_TEMPLATE: "/create-template",
  EDIT_TEMPLATE: "/edit-template/:id",
  SELECT_TEMPLATE: "/select-template",
};

export const PATH_AUTH = {
  ROOT: ROOT_AUTH,
  LOGIN: path(ROOT_AUTH, "/login"),
  REGISTER: path(ROOT_AUTH, "/signup"),
  RESET_PASSWORD: path(ROOT_AUTH, "/reset-password"),
  SET_NEW_PASSWORD: path(ROOT_AUTH, "/set-new-password"),
};

export const SEARCH_PARAMS = {
  CLIENT_ID: "clientId",
  COPY_REGIMEN_ID: "copyRegimenId",
  TEMPLATE_ID: "templateId",
};
