import { t } from "i18next";
import { toast } from "react-toastify";

import { API_ERRORS, API_ERRORS_TEXT, toaster } from "constants/index";
import { IErrorResponse } from "types/api/common";
import { UserRole } from "types/user";

export const formatRequestError = (error: any): string => {
  if (error?.error) {
    const text = API_ERRORS_TEXT[error.error];
    if (text) {
      return t(text);
    }
  }

  if (error?.message && Array.isArray(error.message)) {
    return error.message.join("\n");
  }

  return error?.message ? error.message.toString() : t("errors.wentWrong");
};

export const handleError = (
  error: string,
  errorResponse: IErrorResponse | null,
  userRole: UserRole | undefined
) => {
  if (error === API_ERRORS.validationException) {
    let text = "";
    if (errorResponse?.message instanceof Array) {
      text = errorResponse.message.join("\n");
    } else {
      text = errorResponse?.message || t("errors.validationException");
    }
    toast.error(text, { style: toaster });
    return;
  }

  if (error === API_ERRORS.organizationIsInactive) {
    if (userRole === UserRole.OWNER)
      toast.error(
        t("errors.resetPasswordFailedBecauseOrganizationIsSuspended", {
          organizationName: errorResponse?.organizationName || "",
        }),
        { style: toaster }
      );
    else
      toast.error(
        t("errors.yourOrganizationIsSuspended", {
          organizationName: errorResponse?.organizationName || "",
        }),
        { style: toaster }
      );
    return;
  }

  const text = API_ERRORS_TEXT[error];

  if (text) {
    toast.error(t(text), { style: toaster });
  } else {
    toast.error(t("errors.wentWrong"), { style: toaster });
  }
};
