export const CONTACT_EMAIL = "support@regimenbuilder.com";
export const TERMS_AND_CONDITIONS_LINK =
  "https://regimenbuilder.com/terms-conditions";

export const SEARCH_DEBOUNCE = 500;

export const INIT_PAGE = 1;
export const DEFAULT_LIST_LIMIT = 7;
export const DEFAULT_LIST_LIMIT_TABLET = 6;
export const DEFAULT_LIST_ROW_PADDING = "12px";

export const ERROR_SHOW_TIMEOUT = 4000;

export const CLOSE_ICON_SIZE = 10.5;

export const MONTHS = [
  "month.january",
  "month.february",
  "month.march",
  "month.april",
  "month.may",
  "month.june",
  "month.july",
  "month.august",
  "month.september",
  "month.october",
  "month.november",
  "month.december",
];
