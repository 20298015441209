import styled from "styled-components";

import { colors } from "theme";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 92px;
  flex-direction: column;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px;
  margin-top: 50px;
  background-color: "white";
  box-shadow: 0px 4px 53px ${colors.shadow};
  border-radius: 5px;
`;
