import { UserRole } from "types/user";

export const ROLE_TITLE = {
  [UserRole.OWNER]: "role.owner",
  [UserRole.ORGANIZATION_ADMIN]: "role.organizationAdmin",
  [UserRole.ADMIN]: "role.admin",
  [UserRole.SOLO_PRACTITIONER]: "role.soloPractitioner",
  [UserRole.PRACTITIONER]: "role.practitioner",
  [UserRole.GUEST]: "",
};
