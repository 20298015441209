import { createAsyncThunk } from "@reduxjs/toolkit";

import { IThunkAPI } from "types/common";
import { getOrganizationMetricsService } from "services/dashboard";
import {
  IGetOrganizationMetricsPayload,
  IMetricsResponse,
} from "types/api/dashboard";

export const getOrganizationMetrics = createAsyncThunk<
  IMetricsResponse,
  IGetOrganizationMetricsPayload,
  IThunkAPI
>("getOrganizationMetrics", async (args, thunkAPI) => {
  try {
    const data = await getOrganizationMetricsService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
