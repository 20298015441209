import { UserRole } from "./user";

export enum StripeSubscriptionStatus {
  ACTIVE = "active",
  CANCELED = "canceled",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  PAUSED = "paused",
  TRIALING = "trialing",
  UNPAID = "unpaid",
}

export enum StripePaymentStatus {
  CANCELED = "canceled",
  PROCESSING = "processing",
  REQUIRES_ACTION = "requires_action",
  REQUIRES_CAPTURE = "requires_capture",
  REQUIRES_CONFIRMATION = "requires_confirmation",
  REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  SUCCEEDED = "succeeded",
}

export enum PaymentMethodType {
  CARD = "card",
  OTHER = "other",
}

export interface ISubscription {
  id: string;
  price: number;
  title: string;
  list: string[];
  role: SubscriptionRole;
}

export type SubscriptionRole =
  | UserRole.ORGANIZATION_ADMIN
  | UserRole.SOLO_PRACTITIONER;

export interface IPaymentCard {
  last4: string;
  expMonth: number;
  expYear: number;
}

export interface IPaymentMethod {
  card: IPaymentCard | null;
  type: PaymentMethodType;
}

export interface IUserSubscription {
  status: StripeSubscriptionStatus;
  periodEnd: string;
  paymentMethod: IPaymentMethod | null;
}

export interface ITransaction {
  id: string;
  amount: number;
  currency: string;
  date: string;
  status: StripePaymentStatus;
}
