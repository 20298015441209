import {
  IUpdateProfilePayload,
  IUserResponse,
  IUserResponseData,
} from "types/api/user";
import { apiInstance, formatRequestError } from "utils";
import { formatUser } from "utils/user";

export const getProfileService = async (): Promise<IUserResponse> => {
  try {
    const { data }: { data: IUserResponseData } = await apiInstance.get(
      "profile/me"
    );

    return {
      user: formatUser(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateProfileService = async ({
  email,
  name,
  password,
  oldPassword,
}: IUpdateProfilePayload): Promise<IUserResponse> => {
  try {
    const { data }: { data: IUserResponseData } = await apiInstance.patch(
      "profile/me",
      {
        ...(!!email && { email }),
        ...(!!name && { fullName: name }),
        ...(!!password && { password }),
        ...(!!oldPassword && { oldPassword }),
      }
    );

    return {
      user: formatUser(data),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
