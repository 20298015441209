import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { t } from "i18next";

import { toaster } from "constants/index";
import {
  createClientService,
  deleteClientService,
  getClientService,
  updateClientService,
} from "services/client";
import { getRegimenPDFService, getRegimensListService } from "services/regimen";
import {
  IClientResponse,
  ICreateClientPayload,
  IDeleteClientPayload,
  IGetClientPayload,
  IUpdateClientPayload,
} from "types/api/client";
import { IThunkAPI } from "types/common";
import {
  IGetRegimenPDFPayload,
  IGetRegimenPDFResponse,
  IRegimensListPayload,
  IRegimensListResponse,
} from "types/api/regimen";
import { setPDFLoadingId } from ".";

export const getClient = createAsyncThunk<
  IClientResponse,
  IGetClientPayload,
  IThunkAPI
>("getClient", async (payload, thunkAPI) => {
  try {
    const data = await getClientService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createClient = createAsyncThunk<
  IClientResponse,
  ICreateClientPayload,
  IThunkAPI
>("createClient", async (payload, thunkAPI) => {
  try {
    const data = await createClientService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    toast.success(t("messages.dataSaved"), { style: toaster });

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateClient = createAsyncThunk<
  IClientResponse,
  IUpdateClientPayload,
  IThunkAPI
>("updateClient", async (payload, thunkAPI) => {
  try {
    const data = await updateClientService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    toast.success(t("messages.dataSaved"), { style: toaster });

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteClient = createAsyncThunk<
  void,
  IDeleteClientPayload,
  IThunkAPI
>("deleteClient", async (payload, thunkAPI) => {
  try {
    await deleteClientService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getRegimenList = createAsyncThunk<
  IRegimensListResponse,
  IRegimensListPayload,
  IThunkAPI
>("getRegimenList", async (payload, thunkAPI) => {
  try {
    const data = await getRegimensListService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getRegimenPDF = createAsyncThunk<
  IGetRegimenPDFResponse,
  IGetRegimenPDFPayload,
  IThunkAPI
>("getRegimenPDF", async (payload, thunkAPI) => {
  try {
    thunkAPI.dispatch(setPDFLoadingId(payload.id));
    const data = await getRegimenPDFService(payload);

    if (data?.pdfUrl) {
      window.open(data.pdfUrl, "_blank");
    }

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

