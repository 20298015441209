import React from "react";
import ReactModal, { Props } from "react-modal";

import * as Styled from "./styles";

interface ModalProps extends Props {
  children?: React.ReactNode;
}

const ModalContainer = ({ children, ...rest }: ModalProps) => {
  return (
    <ReactModal
      {...rest}
      ariaHideApp={false}
      className="_"
      overlayClassName="_"
      contentElement={(props, children) => (
        <Styled.Container {...props}>{children}</Styled.Container>
      )}
      overlayElement={(props, contentElement) => (
        <Styled.Overlay {...props}>{contentElement}</Styled.Overlay>
      )}
    >
      {children}
    </ReactModal>
  );
};

export default ModalContainer;
