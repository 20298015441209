import {
  CheckoutStatus,
  ICreateSubscriptionCheckoutData,
  ICreateSubscriptionCheckoutPayload,
  IGetCheckoutSessionStatusData,
  IGetSubscriptionData,
  IGetSubscriptionPlansResponse,
  IGetSubscriptionResponse,
  IGetTransactionsListPayload,
  IGetTransactionsListResponse,
  IGetTransactionsListResponseData,
  PaymentStatus,
} from "types/api/subscription";
import { PaymentMethodType } from "types/subscription";
import { apiInstance, formatRequestError } from "utils";
import { getEnumValue } from "utils/common";
import { numberToDate } from "utils/dates";
import { formatTransaction } from "utils/subscription";

export const getSubscriptionPlansService =
  async (): Promise<IGetSubscriptionPlansResponse> => {
    try {
      const { data }: { data: IGetSubscriptionPlansResponse } =
        await apiInstance.get(`subscription/plans`);

      return data;
    } catch (e: any) {
      const errorMessage: string = formatRequestError(
        e?.response?.data || e.message
      );

      throw errorMessage;
    }
  };

export const createSubscriptionCheckout = async ({
  productId,
}: ICreateSubscriptionCheckoutPayload): Promise<string> => {
  try {
    const { data }: { data: ICreateSubscriptionCheckoutData } =
      await apiInstance.post("subscription/checkout-session", {
        productId,
      });

    return data.clientSecret;
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const getCheckoutSessionStatus = async (
  sessionId: string
): Promise<boolean> => {
  try {
    const { data }: { data: IGetCheckoutSessionStatusData } =
      await apiInstance.get(
        `subscription/checkout-session/${sessionId}/status`
      );

    return (
      data.status === CheckoutStatus.COMPLETE &&
      data.paymentStatus === PaymentStatus.PAID
    );
  } catch (e: any) {
    return false;
  }
};

export const getSubscriptionService =
  async (): Promise<IGetSubscriptionResponse> => {
    try {
      const { data }: { data: IGetSubscriptionData } = await apiInstance.get(
        "subscription/my-subscription"
      );

      const subscription: IGetSubscriptionResponse = {
        subscription: {
          status: data.status,
          periodEnd: numberToDate(data.periodEnd),
          paymentMethod: data.paymentMethod
            ? {
                card: data.paymentMethod.card
                  ? {
                      last4: data.paymentMethod.card.last4,
                      expMonth: data.paymentMethod.card.expMonth,
                      expYear: data.paymentMethod.card.expYear,
                    }
                  : null,
                type: getEnumValue(
                  PaymentMethodType,
                  data.paymentMethod.type,
                  PaymentMethodType.OTHER
                ),
              }
            : null,
        },
      };

      return subscription;
    } catch (e: any) {
      const errorMessage: string = formatRequestError(
        e?.response?.data || e.message
      );

      throw errorMessage;
    }
  };

export const getTransactionsListService = async (
  payload?: IGetTransactionsListPayload
): Promise<IGetTransactionsListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = payload || {};
    const { data }: { data: IGetTransactionsListResponseData } =
      await apiInstance.get("subscription/my-transactions", {
        params: rest,
      });

    return {
      transactions: data.transactions.map((item) => formatTransaction(item)),
      hasMore: data.hasMore,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const getUpdatePaymentDetailsLinkService = async (): Promise<string> => {
  try {
    const { data }: { data: { url: string } } = await apiInstance.get(
      "subscription/update-payment-details-link"
    );

    return data.url;
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
