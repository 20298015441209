import { IBrandResponseData } from "types/api/brands";
import { IBrand } from "types/brands";

export const formatBrand = (data: IBrandResponseData): IBrand => {
  return {
    id: data.id,
    name: data.name,
    isActive: data.isActive,
  };
};
