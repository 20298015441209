import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";

import { ICustomerToggleIsActivePayload, ICustomersState } from "types/store";
import { logout } from "store/reducers/auth/actions";
import { getCustomerList, updateCustomerOrganizationDetails } from "./actions";

const initialState: ICustomersState = {
  error: null,
  loading: false,
  mutationLoading: false,
  customers: [],
  count: 0,
};

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
    toggleIsActive(
      state,
      action: PayloadAction<ICustomerToggleIsActivePayload>
    ) {
      const { id } = action.payload;
      state.customers = state.customers.map((customer) =>
        customer.id === id
          ? { ...customer, isActive: !customer.isActive }
          : customer
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // getCustomerList
    builder.addCase(getCustomerList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.customers = payload.customers;
      state.count = payload.count;
    });
    builder.addCase(getCustomerList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomerList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || null;
    });
    // updateCustomerOrganizationDetails
    builder.addCase(updateCustomerOrganizationDetails.pending, (state) => {
      state.mutationLoading = true;
      state.error = null;
    });
    builder.addCase(updateCustomerOrganizationDetails.fulfilled, (state) => {
      state.mutationLoading = false;
      state.error = null;
    });
    builder.addCase(
      updateCustomerOrganizationDetails.rejected,
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
  },
});

export const { clearError, toggleIsActive } = customersSlice.actions;

export default customersSlice.reducer;
