import {
  IGetOrganizationMetricsPayload,
  IMetricsResponse,
  IMetricsResponseData,
} from "types/api/dashboard";
import { apiInstance, formatRequestError } from "utils";
import { formatMetricsItem } from "utils/dashboard";

export const getOrganizationMetricsService = async ({
  startDate,
  endDate,
}: IGetOrganizationMetricsPayload): Promise<IMetricsResponse> => {
  try {
    const { data }: { data: IMetricsResponseData } = await apiInstance.get(
      "organization/my/metrics",
      {
        params: {
          startDate,
          endDate,
        },
      }
    );

    return {
      clientMetrics: formatMetricsItem(data.clients),
      regimenMetrics: formatMetricsItem(data.regimens),
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
