export const colors = {
  transparent: "transparent",
  black: "#000000",
  white: "#ffffff",
  shadow: "rgba(0, 0, 0, 0.07)",
  shadowMedium: "rgba(0, 0, 0, 0.25)",
  shadowBlack: "rgba(0, 0, 0, 0.5)",
  primary: "#121212",
  secondary: "#2B4262",
  grey: "#B6B6B6",
  lightGrey: "#CCCCCC",
  deepBlush: "rgb(242, 115, 135)",
  guyabano: "#F8F8F8",
  solitude: "#DFE7F2",
  red: "#E74760",
  lightRed: "#F27387",
  lightRedShadow: "rgba(242, 115, 135, 0.08)",
  lightPink: "#EFCBCA",
  lightestPink: "#FEF0F0",
  lightBlue: "#F3F5F8",
  blue: "#366AB3",
  kashmir: "#556E93",
  pianoBlack: "rgb(29, 27, 32)",
  pianoBlackShadow: "rgba(29, 27, 32, 0.08)",
  philippineSilver: "#b3b3b3",
};
