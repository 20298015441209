import { IUserResponseData } from "types/api/user";
import { IOrgUserResponseData } from "types/api/users";
import { IOrgUser, IUser } from "types/user";

export const formatUser = (data: IUserResponseData): IUser => {
  return {
    email: data.email,
    name: data.fullName,
    isSubscribed: data.isSubscriptionActive,
    role: data.role,
    isActive: data.isActive,
  };
};

export const formatOrgUser = (data: IOrgUserResponseData): IOrgUser => {
  return {
    id: data.id,
    email: data.email,
    name: data.fullName,
    role: data.role,
    isActive: data.isActive,
  };
};

export const shortUserName = (name: string): string => {
  return name
    .split(" ")
    .map((word, index, array) => {
      if (array.length === 1) {
        return word;
      }
      if (index === array.length - 1 && word[0]) {
        return `${word[0].toUpperCase()}.`;
      }
      return word;
    })
    .join(" ");
};
