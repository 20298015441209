import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";

import { IAuthSetTokensPayload, IAuthState } from "types/store";
import {
  login,
  logout,
  resetPassword,
  setNewPassword,
  signup,
} from "./actions";

const initialState: IAuthState = {
  error: null,
  loading: false,
  accessToken: "",
  refreshToken: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokens(state, action: PayloadAction<IAuthSetTokensPayload>) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    builder.addMatcher(
      isAnyOf(
        login.pending,
        signup.pending,
        resetPassword.pending,
        setNewPassword.pending
      ),
      (state) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        login.rejected,
        signup.rejected,
        resetPassword.rejected,
        setNewPassword.rejected
      ),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(
      isAnyOf(login.fulfilled, signup.fulfilled),
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
      }
    );
    builder.addMatcher(
      isAnyOf(resetPassword.fulfilled, setNewPassword.fulfilled),
      (state) => {
        state.loading = false;
        state.error = null;
      }
    );
  },
});

export const { clearError, setTokens } = authSlice.actions;

export default authSlice.reducer;
