import { useEffect, useMemo, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { hex, cmyk } from "color-convert";
import { t } from "i18next";
import { isEqual } from "lodash";

import { SVGIcon } from "components/svg-icon";
import { colors } from "theme";
import { CMYKColor } from "types/common";

import * as Styled from "./styles";

interface Props {
  initialColorHEX?: string;
  initialColorCMYK?: CMYKColor;
  onChange?: (args: { hex: string; cmyk: CMYKColor }) => void;
}

const ColorPicker = ({
  initialColorHEX,
  initialColorCMYK,
  onChange,
}: Props) => {
  const { initialHex, initialCMYK } = useMemo(() => {
    if (initialColorHEX) {
      return {
        initialHex: initialColorHEX,
        initialCMYK: hex.cmyk(initialColorHEX),
      };
    } else if (initialColorCMYK) {
      return {
        initialHex: `#${cmyk.hex(initialColorCMYK)}`,
        initialCMYK: initialColorCMYK,
      };
    }
    return {
      initialHex: colors.black,
      initialCMYK: hex.cmyk(colors.black),
    };
  }, [initialColorHEX, initialColorCMYK]);

  const [hexColor, setHexColor] = useState(initialHex);
  const [cmykColor, setCmykColor] = useState<CMYKColor>(initialCMYK);
  const containerRef = useRef<HTMLDivElement>(null);

  const [showColorPicker, setShowColorPicker] = useState(false);

  const isUpdatingHEX = useRef(!!initialColorHEX);
  const isUpdatingCMYK = useRef(!!initialColorCMYK);

  useEffect(() => {
    if (!isUpdatingCMYK.current) {
      const newCMYKColor = hex.cmyk(hexColor);
      if (!isEqual(newCMYKColor, cmykColor)) {
        isUpdatingHEX.current = true;
        setCmykColor(newCMYKColor);
      }
    } else {
      isUpdatingCMYK.current = false;
    }
  }, [hexColor]);

  useEffect(() => {
    setCmykColor(initialCMYK);
  }, [initialCMYK]);

  useEffect(() => {
    setHexColor(initialHex);
  }, [initialHex]);

  useEffect(() => {
    if (!isUpdatingHEX.current) {
      const newHexColor = `#${cmyk.hex(cmykColor)}`;
      if (newHexColor !== hexColor) {
        isUpdatingCMYK.current = true;
        setHexColor(newHexColor);
      }
    } else {
      isUpdatingHEX.current = false;
    }
  }, [cmykColor]);

  useEffect(() => {
    if (onChange) {
      onChange({ hex: hexColor, cmyk: cmykColor });
    }
  }, [hexColor, cmykColor]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowColorPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Styled.Container ref={containerRef}>
      <Styled.ColorPickerContainer $visible={showColorPicker}>
        <Styled.CloseIconContainer onClick={() => setShowColorPicker(false)}>
          <SVGIcon name="close" width={10} height={10} />
        </Styled.CloseIconContainer>
        <HexColorPicker color={hexColor} onChange={setHexColor} />
        <Styled.InputsContainer>
          <Styled.InputContainer>
            <Styled.Label>{t("color.hex")}</Styled.Label>
            <Styled.Input
              type="text"
              value={hexColor}
              $width="90px"
              onChange={(e) => setHexColor(e.target.value)}
            />
          </Styled.InputContainer>

          <Styled.CMYKContainer>
            <Styled.Label>{t("color.cmyk")}</Styled.Label>
            <Styled.Input
              type="number"
              value={cmykColor[0]}
              $width="40px"
              onChange={(e) =>
                setCmykColor([
                  +e.target.value,
                  cmykColor[1],
                  cmykColor[2],
                  cmykColor[3],
                ])
              }
            />
            <Styled.Input
              type="number"
              value={cmykColor[1]}
              $width="40px"
              onChange={(e) =>
                setCmykColor([
                  cmykColor[0],
                  +e.target.value,
                  cmykColor[2],
                  cmykColor[3],
                ])
              }
            />
            <Styled.Input
              type="number"
              value={cmykColor[2]}
              $width="40px"
              onChange={(e) =>
                setCmykColor([
                  cmykColor[0],
                  cmykColor[1],
                  +e.target.value,
                  cmykColor[3],
                ])
              }
            />
            <Styled.Input
              type="number"
              value={cmykColor[3]}
              $width="40px"
              onChange={(e) =>
                setCmykColor([
                  cmykColor[0],
                  cmykColor[1],
                  cmykColor[2],
                  +e.target.value,
                ])
              }
            />
          </Styled.CMYKContainer>
        </Styled.InputsContainer>
      </Styled.ColorPickerContainer>

      <Styled.ColorBox
        $color={hexColor}
        onClick={() => setShowColorPicker((prev) => !prev)}
      />
      <Styled.EditButtonContainer>
        <SVGIcon
          onClick={() => setShowColorPicker((prev) => !prev)}
          name="edit"
          height={35}
          width={35}
        />
      </Styled.EditButtonContainer>
    </Styled.Container>
  );
};

export default ColorPicker;
