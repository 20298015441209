export const API_ERRORS = {
  jwtExpired: "JwtExpiredException",
  invalidJwt: "InvalidJwtException",
  unauthorized: "UnauthorizedException",
  validationException: "ValidationException",
  wrongCredentials: "IncorrectEmailOrPasswordException",
  incorrectPassword: "IncorrectPasswordException",
  organizationNotFoundByUserId: "OrganizationNotFoundByUserIdException",
  userNotFoundById: "UserNotFoundByIdException",
  userNotFoundByEmail: "UserNotFoundByEmailException",
  userAlreadyExists: "UserAlreadyExistsException",
  userCreationFailed: "UserCreationFailedException",
  invalidUserRole: "InvalidUserRoleException",
  oneTimeTokenIsNotValid: "OneTimeTokenIsNotValidException",
  brandAlreadyExists: "BrandAlreadyExistsException",
  brandCreationFailed: "BrandCreationFailedException",
  brandNotFoundById: "BrandNotFoundByIdException",
  brandUpdateFailed: "BrandUpdateFailedException",
  brandDeletionFailed: "BrandDeletionFailedException",
  productNotFoundById: "ProductNotFoundByIdException",
  productCreationFailed: "ProductCreationFailedException",
  productUpdateFailed: "ProductUpdateFailedException",
  clientNotFoundById: "ClientNotFoundByIdException",
  clientCreationFailed: "ClientCreationFailedException",
  clientUpdateFailed: "ClientUpdateFailedException",
  userIsInactive: "UserIsInactiveException",
  stripeCustomerNotFound: "StripeCustomerNotFoundException",
  userTransactionsReceivingFail: "UserTransactionsReceivingFail",
  stripeSubscriptionNotFound: "StripeSubscriptionNotFoundException",
  portalSessionCreationFail: "PortalSessionCreationFail",
  organizationIsInactive: "OrganizationIsInactiveException",
  userIsUnsubscribed: "UserIsUnsubscribedException",
  oneTimeTokenIsAlreadyExistsException: "OneTimeTokenIsAlreadyExistsException",
};

export const API_ERRORS_TEXT = {
  [API_ERRORS.wrongCredentials]: "errors.wrongCredentials",
  [API_ERRORS.incorrectPassword]: "errors.incorrectPassword",
  [API_ERRORS.organizationNotFoundByUserId]:
    "errors.organizationNotFoundByUserId",
  [API_ERRORS.userNotFoundById]: "errors.userNotFoundById",
  [API_ERRORS.userNotFoundByEmail]: "errors.userNotFoundByEmail",
  [API_ERRORS.userAlreadyExists]: "errors.userAlreadyExists",
  [API_ERRORS.userCreationFailed]: "errors.userCreationFailed",
  [API_ERRORS.invalidUserRole]: "errors.invalidUserRole",
  [API_ERRORS.oneTimeTokenIsNotValid]: "errors.oneTimeTokenIsNotValid",
  [API_ERRORS.brandAlreadyExists]: "errors.brandAlreadyExists",
  [API_ERRORS.brandCreationFailed]: "errors.brandCreationFailed",
  [API_ERRORS.brandNotFoundById]: "errors.brandNotFoundById",
  [API_ERRORS.brandUpdateFailed]: "errors.brandUpdateFailed",
  [API_ERRORS.brandDeletionFailed]: "errors.brandDeletionFailed",
  [API_ERRORS.productCreationFailed]: "errors.productCreationFailed",
  [API_ERRORS.productNotFoundById]: "errors.productNotFoundById",
  [API_ERRORS.productUpdateFailed]: "errors.productUpdateFailed",
  [API_ERRORS.clientNotFoundById]: "errors.clientNotFoundById",
  [API_ERRORS.clientCreationFailed]: "errors.clientCreationFailed",
  [API_ERRORS.clientUpdateFailed]: "errors.clientUpdateFailed",
  [API_ERRORS.userIsInactive]: "errors.userIsInactive",
  [API_ERRORS.stripeCustomerNotFound]: "errors.stripeCustomerNotFound",
  [API_ERRORS.userTransactionsReceivingFail]:
    "errors.userTransactionsReceivingFail",
  [API_ERRORS.stripeSubscriptionNotFound]: "errors.stripeSubscriptionNotFound",
  [API_ERRORS.portalSessionCreationFail]: "errors.portalSessionCreationFail",
  [API_ERRORS.userIsUnsubscribed]: "errors.userIsUnsubscribed",
  [API_ERRORS.oneTimeTokenIsAlreadyExistsException]:
    "errors.resetPasswordLinkAlreadySent",
  [API_ERRORS.jwtExpired]: "errors.jwtExpired",
};

export const REFRESH_TOKENS_URL = "refresh-tokens";

export const ERRORS_TO_NOT_SHOW = [
  API_ERRORS.wrongCredentials,
  API_ERRORS.validationException,
  API_ERRORS.incorrectPassword,
  API_ERRORS.invalidJwt,
];
