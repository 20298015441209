import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import {
  IAddRoutineItemPayload,
  IRegimenBuilderState,
  IRemoveProcedurePayload,
  IReorderRoutinePayload,
  ISetProductNotesPayload,
  ISetRegimenProcedureNotePayload,
  ISetRegimenProcedurePayload,
} from "types/store";
import { IRegimen } from "types/regimen";
import { logout } from "store/reducers/auth/actions";
import {
  createRegimen,
  createRegimenPDFPreview,
  deleteRegimen,
  getRegimenForCopy,
  getRegimenForEdit,
  updateRegimen,
} from "./actions";

const initialState: IRegimenBuilderState = {
  // builder
  morningRoutine: [],
  eveningRoutine: [],
  notesForClient: "",
  internalNotes: "",
  procedures: [
    {
      id: uuidv4(),
      procedure: null,
      notesForClient: "",
    },
  ],
  isDirty: false,
  // fetching
  mutationLoading: false,
  loading: false,
  error: null,
  pdfData: null,
  copyRegimen: null,
  editRegimen: null,
};

const regimenBuilderSlice = createSlice({
  name: "regimenBuilder",
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    addMorningRoutine(state, action: PayloadAction<IAddRoutineItemPayload>) {
      const { element, index } = action.payload;
      state.morningRoutine.splice(index, 0, {
        product: {
          ...element,
          dndId: uuidv4(),
        },
        notesForClient: action.payload.notesForClient || element.note,
      });
      state.isDirty = true;
    },
    addEveningRoutine(state, action: PayloadAction<IAddRoutineItemPayload>) {
      const { element, index } = action.payload;
      state.eveningRoutine.splice(index, 0, {
        product: {
          ...element,
          dndId: uuidv4(),
        },
        notesForClient: action.payload.notesForClient || element.note,
      });
      state.isDirty = true;
    },
    reorderMorningRoutine(
      state,
      action: PayloadAction<IReorderRoutinePayload>
    ) {
      const { sourceIndex, destinationIndex } = action.payload;
      const [reorderedItem] = state.morningRoutine.splice(sourceIndex, 1);
      state.morningRoutine.splice(destinationIndex, 0, reorderedItem);
      state.isDirty = true;
    },
    reorderEveningRoutine(
      state,
      action: PayloadAction<IReorderRoutinePayload>
    ) {
      const { sourceIndex, destinationIndex } = action.payload;
      const [reorderedItem] = state.eveningRoutine.splice(sourceIndex, 1);
      state.eveningRoutine.splice(destinationIndex, 0, reorderedItem);
      state.isDirty = true;
    },
    removeMorningRoutineItem(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.morningRoutine.findIndex(
        (item) => item.product.dndId === id
      );
      state.morningRoutine.splice(index, 1);
      state.isDirty = true;
    },
    removeEveningRoutineItem(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.eveningRoutine.findIndex(
        (item) => item.product.dndId === id
      );
      state.eveningRoutine.splice(index, 1);
      state.isDirty = true;
    },
    setNotesForClient(state, action: PayloadAction<string>) {
      state.notesForClient = action.payload;
      state.isDirty = true;
    },
    setInternalNotes(state, action: PayloadAction<string>) {
      state.internalNotes = action.payload;
      state.isDirty = true;
    },
    setMorningProductNotesForClient(
      state,
      action: PayloadAction<ISetProductNotesPayload>
    ) {
      const { dndId, value } = action.payload;
      const index = state.morningRoutine.findIndex(
        (item) => item.product.dndId === dndId
      );
      state.morningRoutine[index].notesForClient = value;
      state.isDirty = true;
    },
    setEveningProductNotesForClient(
      state,
      action: PayloadAction<ISetProductNotesPayload>
    ) {
      const { dndId, value } = action.payload;
      const index = state.eveningRoutine.findIndex(
        (item) => item.product.dndId === dndId
      );
      state.eveningRoutine[index].notesForClient = value;
      state.isDirty = true;
    },
    setProcedure(state, action: PayloadAction<ISetRegimenProcedurePayload>) {
      const { id, value } = action.payload;
      const index = state.procedures.findIndex((item) => item.id === id);
      state.procedures[index].procedure = value;
      state.procedures[index].notesForClient = value?.note || "";
      state.isDirty = true;
    },
    setProcedureNote(
      state,
      action: PayloadAction<ISetRegimenProcedureNotePayload>
    ) {
      const { id, value } = action.payload;
      const index = state.procedures.findIndex((item) => item.id === id);
      state.procedures[index].notesForClient = value;
      state.isDirty = true;
    },
    addNewProcedure(state) {
      state.procedures.push({
        id: uuidv4(),
        procedure: null,
        notesForClient: "",
      });
      state.isDirty = true;
    },
    removeProcedure(state, action: PayloadAction<IRemoveProcedurePayload>) {
      const { id } = action.payload;
      const index = state.procedures.findIndex((item) => item.id === id);
      state.procedures.splice(index, 1);
      state.isDirty = true;
    },
    setRegimenBuilderValues(
      state,
      action: PayloadAction<Omit<IRegimen, "clientId">>
    ) {
      const {
        morningRoutine,
        eveningRoutine,
        procedures,
        internalNotes,
        notesForClient,
      } = action.payload;
      state.morningRoutine = morningRoutine;
      state.eveningRoutine = eveningRoutine;
      state.procedures = procedures;
      state.internalNotes = internalNotes;
      state.notesForClient = notesForClient;
      state.isDirty = false;
    },
    deletePDF(state) {
      state.pdfData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // createRegimenPDFPreview
    builder.addCase(createRegimenPDFPreview.fulfilled, (state, { payload }) => {
      state.mutationLoading = false;
      state.error = null;
      state.pdfData = payload.pdfData;
    });
    // getRegimenForEdit
    builder.addCase(getRegimenForEdit.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editRegimen = payload.regimen;
    });
    // getRegimenForCopy
    builder.addCase(getRegimenForCopy.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.copyRegimen = payload.regimen;
    });
    // Matchers
    builder.addMatcher(
      isAnyOf(
        createRegimen.fulfilled,
        createRegimenPDFPreview.pending,
        updateRegimen.fulfilled,
        deleteRegimen.fulfilled
      ),
      (state) => {
        state.mutationLoading = false;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createRegimen.pending,
        createRegimenPDFPreview.pending,
        updateRegimen.pending,
        deleteRegimen.pending
      ),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createRegimen.rejected,
        createRegimenPDFPreview.rejected,
        updateRegimen.rejected,
        deleteRegimen.rejected
      ),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(
      isAnyOf(getRegimenForEdit.pending, getRegimenForCopy.pending),
      (state) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(getRegimenForEdit.rejected, getRegimenForCopy.rejected),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      }
    );
  },
});

export const {
  clearState,
  addEveningRoutine,
  addMorningRoutine,
  reorderEveningRoutine,
  reorderMorningRoutine,
  removeEveningRoutineItem,
  removeMorningRoutineItem,
  setNotesForClient,
  setInternalNotes,
  setMorningProductNotesForClient,
  setEveningProductNotesForClient,
  setProcedure,
  setProcedureNote,
  addNewProcedure,
  removeProcedure,
  setRegimenBuilderValues,
  deletePDF,
} = regimenBuilderSlice.actions;

export default regimenBuilderSlice.reducer;
