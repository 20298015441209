import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganizationUsersListService } from "services/organizations-users";
import {
  IOrganizationUsersListPayload,
  IOrganizationUsersListResponse,
} from "types/api/organizations-users";

import { IThunkAPI } from "types/common";

export const getOrganizationUsersList = createAsyncThunk<
  IOrganizationUsersListResponse,
  IOrganizationUsersListPayload,
  IThunkAPI
>("getOrganizationUsersList", async (args, thunkAPI) => {
  try {
    const data = await getOrganizationUsersListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
