import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createRegimenPDFPreviewService,
  createRegimenService,
  deleteRegimenService,
  getRegimenService,
  updateRegimenService,
} from "services/regimen";
import { getClient } from "store/reducers/client/actions";
import {
  ICreateRegimenPDFPreviewPayload,
  ICreateRegimenPDFPreviewResponse,
  ICreateRegimenPayload,
  IDeleteRegimenPayload,
  IGetRegimenPayload,
  IGetRegimenResponse,
  IUpdateRegimenPayload,
} from "types/api/regimen";
import { IThunkAPI } from "types/common";
import { setRegimenBuilderValues } from ".";

export const createRegimenPDFPreview = createAsyncThunk<
  ICreateRegimenPDFPreviewResponse,
  ICreateRegimenPDFPreviewPayload,
  IThunkAPI
>("createRegimenPDFPreview", async (payload, thunkAPI) => {
  try {
    const data = await createRegimenPDFPreviewService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createRegimen = createAsyncThunk<
  void,
  ICreateRegimenPayload,
  IThunkAPI
>("createRegimen", async (payload, thunkAPI) => {
  try {
    await createRegimenService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateRegimen = createAsyncThunk<
  void,
  IUpdateRegimenPayload,
  IThunkAPI
>("updateRegimen", async (payload, thunkAPI) => {
  try {
    await updateRegimenService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteRegimen = createAsyncThunk<
  void,
  IDeleteRegimenPayload,
  IThunkAPI
>("deleteRegimen", async (payload, thunkAPI) => {
  try {
    await deleteRegimenService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getRegimenForCopy = createAsyncThunk<
  IGetRegimenResponse,
  IGetRegimenPayload,
  IThunkAPI
>("getRegimenForCopy", async (payload, thunkAPI) => {
  try {
    const data = await getRegimenService(payload);

    if (data.regimen) {
      thunkAPI.dispatch(setRegimenBuilderValues(data.regimen));
    }

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getRegimenForEdit = createAsyncThunk<
  IGetRegimenResponse,
  IGetRegimenPayload,
  IThunkAPI
>("getRegimenForEdit", async (payload, thunkAPI) => {
  try {
    const data = await getRegimenService(payload);

    if (data.regimen) {
      thunkAPI.dispatch(setRegimenBuilderValues(data.regimen));
      thunkAPI.dispatch(getClient({ id: data.regimen.clientId }));
    }

    if (payload?.onSuccess) {
      payload.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
