import { IGetTransactionResponseData } from "types/api/subscription";
import { ITransaction } from "types/subscription";
import { numberToDate } from "./dates";

export const formatTransaction = (
  data: IGetTransactionResponseData
): ITransaction => {
  return {
    id: data.id,
    amount: data.amount / 100,
    currency: data.currency,
    date: numberToDate(data.date),
    status: data.status,
  };
};
