import dayjs from "dayjs";
import { DATE_FORMAT, DATE_TIME_FORMAT } from "constants/index";

export const timestampToFormatDate = (timestamp: string) => {
  return dayjs(timestamp).format(DATE_FORMAT);
};

export const timestampToFormatDateAndTime = (timestamp: string) => {
  return dayjs(timestamp).format(DATE_TIME_FORMAT);
};

export const timestampToDate = (timestamp: string) => {
  return dayjs(timestamp).toDate();
};

export const dateToTimestamp = (date: Date): string => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const getYear = (date: Date) => {
  return dayjs(date).year();
};

export const getMonth = (date: Date) => {
  return dayjs(date).month();
};

export const numberToDate = (number: number): string => {
  return dayjs(number * 1000).format(DATE_FORMAT);
};

export const getToday = () => {
  return dayjs().toDate();
};

export const getStartOfWeek = (date: Date) => {
  return dayjs(date).startOf("week").toDate();
};

export const getEndOfWeek = (date: Date) => {
  return dayjs(date).endOf("week").toDate();
};

export const getStartOfMonth = (date: Date) => {
  return dayjs(date).startOf("month").toDate();
};

export const getEndOfMonth = (date: Date) => {
  return dayjs(date).endOf("month").toDate();
};

export const getStartOfYear = (date: Date) => {
  return dayjs(date).startOf("year").toDate();
};

export const startOfLastWeek = (date: Date) => {
  return dayjs(date).subtract(1, "week").startOf("week").toDate();
};

export const endOfLastWeek = (date: Date) => {
  return dayjs(date).subtract(1, "week").endOf("week").toDate();
};

export const startOfLastMonth = (date: Date) => {
  return dayjs(date).subtract(1, "month").startOf("month").toDate();
};

export const endOfLastMonth = (date: Date) => {
  return dayjs(date).subtract(1, "month").endOf("month").toDate();
};
