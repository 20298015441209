import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { logout } from "store/reducers/auth/actions";
import { ITemplatesState } from "types/store";
import {
  createTemplate,
  deleteTemplate,
  getTemplate,
  getTemplatesList,
  updateTemplate,
} from "./actions";

const initialState: ITemplatesState = {
  error: null,
  loading: false,
  mutationLoading: false,
  templates: [],
  template: null,
  count: 0,
};

const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
    // getTemplatesList
    builder.addCase(getTemplatesList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.templates = payload.templates;
      state.count = payload.count;
    });
    // getTemplate
    builder.addCase(getTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.template = payload.template;
    });
    // Matchers
    builder.addMatcher(
      isAnyOf(getTemplatesList.pending, getTemplate.pending),
      (state) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(getTemplatesList.rejected, getTemplate.rejected),
      (state, { payload }) => {
        state.loading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createTemplate.pending,
        updateTemplate.pending,
        deleteTemplate.pending
      ),
      (state) => {
        state.mutationLoading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createTemplate.rejected,
        updateTemplate.rejected,
        deleteTemplate.rejected
      ),
      (state, { payload }) => {
        state.mutationLoading = false;
        state.error = payload || null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        createTemplate.fulfilled,
        updateTemplate.fulfilled,
        deleteTemplate.fulfilled
      ),
      (state) => {
        state.mutationLoading = false;
        state.error = null;
      }
    );
  },
});

export const { clearError } = templatesSlice.actions;

export default templatesSlice.reducer;
