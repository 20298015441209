import { createAsyncThunk } from "@reduxjs/toolkit";

import { IThunkAPI } from "types/common";
import {
  IBrandsListPayload,
  IBrandsListResponse,
  ICreateBrandPayload,
  IDeleteBrandPayload,
  IUpdateBrandPayload,
} from "types/api/brands";
import {
  createBrandService,
  deleteBrandService,
  getBrandsListService,
  updateBrandService,
} from "services/brands";

export const getBrandList = createAsyncThunk<
  IBrandsListResponse,
  IBrandsListPayload | undefined,
  IThunkAPI
>("getBrandList", async (args, thunkAPI) => {
  try {
    const data = await getBrandsListService(args);

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getBrandActiveList = createAsyncThunk<
  IBrandsListResponse,
  IBrandsListPayload | undefined,
  IThunkAPI
>("getBrandActiveList", async (args, thunkAPI) => {
  try {
    const data = await getBrandsListService({ ...args, isActive: true });

    if (args?.onSuccess) {
      args.onSuccess();
    }

    return data;
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createBrand = createAsyncThunk<
  void,
  ICreateBrandPayload,
  IThunkAPI
>("createBrand", async (payload, thunkAPI) => {
  try {
    await createBrandService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateBrand = createAsyncThunk<
  void,
  IUpdateBrandPayload,
  IThunkAPI
>("updateBrand", async (payload, thunkAPI) => {
  try {
    await updateBrandService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteBrand = createAsyncThunk<
  void,
  IDeleteBrandPayload,
  IThunkAPI
>("deleteBrand", async (payload, thunkAPI) => {
  try {
    await deleteBrandService(payload);

    if (payload?.onSuccess) {
      payload.onSuccess();
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
