import {
  ICustomerListPayload,
  ICustomerListResponse,
  ICustomerListResponseData,
  IUpdateCustomerOrganizationDetailsPayload,
} from "types/api/customers";
import { apiInstance, formatRequestError } from "utils";
import { formatCustomer } from "utils/customers";

export const getCustomerListService = async (
  args?: ICustomerListPayload
): Promise<ICustomerListResponse> => {
  try {
    const { onError, onSuccess, ...rest } = args || {};
    const { data }: { data: ICustomerListResponseData } = await apiInstance.get(
      "profile/customers",
      {
        params: rest,
      }
    );

    return {
      customers: data.customers.map((item) => formatCustomer(item)),
      count: data.metadata.itemsAmount,
    };
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};

export const updateCustomerOrganizationDetailsService = async ({
  customerId,
  customerEmail,
  customerFullName,
  isActive,
  logoFile,
  organizationName,
  pdfColorHexMorningRoutine,
  pdfColorHexEveningRoutine,
  pdfColorHexNotes,
  pdfColorHexFooter,
}: IUpdateCustomerOrganizationDetailsPayload): Promise<void> => {
  try {
    const form = new FormData();
    !!customerEmail && form.append("customerEmail", customerEmail);
    !!customerFullName && form.append("customerFullName", customerFullName);
    (isActive === true || isActive === false) &&
      form.append("isActive", String(isActive));

    !!pdfColorHexMorningRoutine &&
      form.append("pdfColorHexMorningRoutine", pdfColorHexMorningRoutine);
    !!pdfColorHexEveningRoutine &&
      form.append("pdfColorHexEveningRoutine", pdfColorHexEveningRoutine);
    !!pdfColorHexNotes && form.append("pdfColorHexNotes", pdfColorHexNotes);
    !!pdfColorHexFooter && form.append("pdfColorHexFooter", pdfColorHexFooter);
    !!organizationName && form.append("organizationName", organizationName);
    !!logoFile && form.append("logoFile", logoFile);

    await apiInstance.patch(`organization/customer/${customerId}`, form);
  } catch (e: any) {
    const errorMessage: string = formatRequestError(
      e?.response?.data || e.message
    );

    throw errorMessage;
  }
};
